import {VFC} from "react"
import {InputFieldProps} from "../../../../../../types/form/controls"
import {InputField} from "../../../../form/controls/input-field"
import {useTranslation} from "react-i18next"

export const Expiration: VFC<InputFieldProps> = (props) => {
    const { t } = useTranslation()

    return (
        <InputField
            label={t('form.card.expiration')}
            name='card.expiration'
            autoComplete="card.expiration"
            mask='99/99'
            placeholder='MM/YY'
            {...props}
        />
    )
}
