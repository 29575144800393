import {TimeoutConfig} from "rxjs"
import {UseServerProvidedObservableConfig} from "../../../types/types"

export const makeTimoutConfig = <ST, RT>(
    {
        settings,
        fallbackTo
    }: Pick<UseServerProvidedObservableConfig<ST, RT>, 'settings' | 'fallbackTo'>
): Nullable<TimeoutConfig<RT>> => {
    if(!settings || !settings.waitFor) {
        return null
    }

    const { waitFor } = settings

    if(!waitFor) {
        return null
    }

    const timeoutConfig: TimeoutConfig<RT> = {
        each: waitFor
    }
    if(fallbackTo) {
        timeoutConfig.with = () => fallbackTo
    }

    return timeoutConfig
}
