import {useEffect, useState} from "react"
import {useAppSelector} from "../../../../../store/hooks/use-app-selector"
import {getPaymentProvider} from "../../../../../store/slices/card/selectors"
import {PaymentProvider} from "../../../../../types/payment-provider"
import {ChaseFormTracker} from "../../../../components/customer/card/chase-connector/chase-form-tracker"

export enum ChaseFormStatus {
    IGNORED = 'ignored',
    NOT_VALID = 'not_valid',
    VALID = 'valid',
}

export const useChaseFormStatus = () => {
    const paymentProvider = useAppSelector(state => getPaymentProvider(state.card))

    const [trackerIsReady, setTrackerIsReady] = useState(false)
    const [status, setStatus] = useState(
        paymentProvider === PaymentProvider.CHASE_DHPP ? ChaseFormStatus.NOT_VALID : ChaseFormStatus.IGNORED
    )

    useEffect(() => {
        setStatus(paymentProvider === PaymentProvider.CHASE_DHPP ? ChaseFormStatus.NOT_VALID : ChaseFormStatus.IGNORED)
    }, [
        paymentProvider
    ])

    useEffect(() => {
        window.chaseForm = new ChaseFormTracker()
        setTrackerIsReady(true)
    }, [])

    useEffect(() => {
        if (!trackerIsReady) {
            return
        }

        const unsubscribe = window.chaseForm.subscribe(({event, payload}) => {
            if (event === 'changed') {
                setStatus(payload.isValid ? ChaseFormStatus.VALID : ChaseFormStatus.NOT_VALID)
            }
        })

        return () => {
            unsubscribe()
        }
    }, [
        setStatus,
        trackerIsReady
    ])

    return status
}