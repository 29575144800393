import {VFC} from "react"
import {useTranslation} from "react-i18next"
import {usePaymentProviderSwitch} from "../../../../pages/session/customer/hooks/use-payment-provider-switch"
import {composeCountryOptions} from "../helpers/compose-country-options"
import {useTruncateDependenciesOnChange} from "./hooks/use-truncate-dependencies-on-change"
import {FormikDropdownField as DropdownField} from "../../../form/controls/dropdown-field/formik-dropdown-field"

type CountryProps = {
    value: string,
    error: string | null | undefined,
}

const countryOptions = composeCountryOptions()

export const Country: VFC<CountryProps> = (
    props
) => {
    const { t } = useTranslation()

    usePaymentProviderSwitch(props.value)

    useTruncateDependenciesOnChange(props.value)

    return (
        <DropdownField
            {...props}
            label={t('form.address.country')}
            name="address.country"
            options={countryOptions}
            searchable={true}
        />
    )
}
