import {CustomerForm} from "../../../../../types/forms"
import {OpaqueData, PaymentDataWithCard} from "../../../../../types/provider/accept-js"
import {ProcessingInput} from "../../../../../api/lira/types"
import {parse} from "../../../../../services/card/expiration-parser"
import {composeFullName} from "../../../../../services/address/compose-full-name"

export const composeAcceptJsInput = ({card, address}: CustomerForm): PaymentDataWithCard => {
    const {month, year} = parse(card.expiration)

    return {
        cardData: {
            cardNumber: card.number,
            month,
            year,
            cardCode: card.verificationNumber,
            zip: address.postalCode,
            fullName: composeFullName(address)
        }
    }
}

export const composeProcessingInput = (data: CustomerForm, nonce: OpaqueData): ProcessingInput => {
    const {month, year} = parse(data.card.expiration)

    return {
        expirationMonth: month,
        expirationYear: year,
        nonce,
        country: data.address.country
    }
}
