import {useEffect, useState} from "react"
import {initiateCheckout} from "../../../../../../api/jupiter/jupiter"
import {useAppSelector} from "../../../../../../store/hooks/use-app-selector"
import {sessionIsLoaded} from "../../../../../../store/slices/session/selectors"

export const useRequestCheckoutId = () => {
    const sessionExists = useAppSelector(state => sessionIsLoaded(state.session))

    const [checkoutId, setCheckoutId] = useState('')

    useEffect(() => {
        if (!sessionExists) {
            return
        }

        initiateCheckout().then(({id}) => {
            setCheckoutId(id)
        })
    }, [setCheckoutId, sessionExists])

    return checkoutId
}