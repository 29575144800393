import {Observable} from "rxjs"
import {useMemo} from "react"
import {UseServerProvidedObservableConfig} from "../../types/types"
import {useServerProvidedObservable} from "./use-server-provided-observable"
import {eventSourceAdapter} from "./adapters/event-source-adapter"

export const useSseObservable = <ST, RT = ST>(
    config: UseServerProvidedObservableConfig<ST, RT>
): Observable<RT> => {
    const client = useMemo(
        () => eventSourceAdapter<ST>(),
        []
    )

    return useServerProvidedObservable(client, config)
}
