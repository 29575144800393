import {useNavigate} from "react-router-dom"
import {useSessionStateSelector} from "../../store/slices/session/hooks"
import {getSessionParams} from "../../store/slices/session/selectors"
import {useCallback} from "react"
import {WithGetLink} from "../../services/utility/routes/types"

type SessionNavigateFn = (params: WithGetLink, extra?: Record<string, string>) => void

export const useSessionNavigate = (): SessionNavigateFn => {
    const sessionParams = useSessionStateSelector(state => getSessionParams(state))

    const navigate = useNavigate()

    return useCallback(({getLink}: WithGetLink, extra: Record<string, string> = {}) => {
        return navigate(getLink({
            ...sessionParams,
            ...extra
        }))
    }, [sessionParams])
}
