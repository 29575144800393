import {ServerProvidedClient, ServerProvidedConnection} from "../../../types/types"

const eventSourceWrapper = <T>(eventSource: EventSource): ServerProvidedConnection<T> => {

    let event: string
    let listener: (e: Event) => void

    return {
        listen(
            eventName: string,
            onEvent: (data: T) => void,
        ): void {
            listener = (e) => {
                const messageEvent = e as MessageEvent

                onEvent(
                    JSON.parse<T>(messageEvent.data)
                )
            }

            event = eventName

            eventSource.addEventListener(event, listener)

            eventSource.onerror = (e) => {
                console.log('Error from Event Source', {e})
                // todo - figure out why it is triggered when it doesn't need to trigger and then reenable below
                // onError(new Error('Event source produced error'))
            }
        },
        unsubscribe(): void {
            eventSource.removeEventListener(event, listener)
            eventSource.close()
        }
    }
}

export const eventSourceAdapter = <T>(): ServerProvidedClient<T> => {
    return {
        subscribe(channelName: string): ServerProvidedConnection<T> {
            return eventSourceWrapper(new EventSource(channelName))
        }

    }
}
