import {useState, VFC} from "react"
import {Toast} from "@malwarebytes/react-components/build"
import {useSessionStateSelector} from "../../../../store/slices/session/hooks"
import {getLocalError} from "../../../../store/slices/session/selectors"
import styled from "styled-components"

const Wrapper = styled.div`
  margin: -1.5rem -1.5rem 1.125rem auto
`

export const ErrorMessage: VFC = () => {
    const localError = useSessionStateSelector(state => getLocalError(state))
    const [showMessage, setShowMessage] = useState(true)
    const toggleShowMessage = () => setShowMessage(!showMessage)

    if(!localError) {
        return null
    }

    return (
        <Wrapper>
            <Toast show={showMessage}
                   variant={"danger"}
                   onClose={toggleShowMessage}
            >
                <Toast.Header>
                    <span className="me-auto">
                        {localError}
                    </span>
                </Toast.Header>
            </Toast>
        </Wrapper>
    )
}
