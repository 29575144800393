import {useSessionNavigate} from "../../../../../../../../hooks/session/use-session-navigate"
import {ProviderSubmitFn} from "../../use-submit"
import {useAcceptJsWithConfig} from "./use-accept-js"
import {CustomerForm} from "../../../../../../../../types/forms"
import {startProcessing} from "../../../../../../../../api/lira/lira"
import {routes} from "../../../../../../../../config/routes/routes.config"
import {composeAcceptJsInput, composeProcessingInput} from "../../../../helpers/input-composing"
import {decodeAuthNetCollectorId} from "../../../../helpers/decode-collector-id"

export const useAuthNetSubmit = (): ProviderSubmitFn => {
    const navigate = useSessionNavigate()

    const {dispatchData} = useAcceptJsWithConfig()

    return async (data: CustomerForm) => {
        const {opaqueData} = await dispatchData(
            composeAcceptJsInput(data)
        )

        const {success, collectorId} = await startProcessing(
            composeProcessingInput(data, opaqueData)
        )

        if(!success) {
            throw new Error('Not successful processing initiation')
        }

        navigate(
            routes.session.processing.authNet,
            {
                collectorId: decodeAuthNetCollectorId(collectorId)
            }
        )
    }
}
