import {SessionParams, SessionState} from "./types"
import {CustomerFormAddress} from "../../../types/forms"
import {RootState} from "../../store"
import {LoadingState} from "../../../types/store"
import {composeFullName} from "../../../services/address/compose-full-name"
import {Flow} from "../../../types/flow"

export const getSessionState = (state: RootState): SessionState => state.session

export const getLoadingStatus = (state: SessionState): LoadingState => state.loadingStatus

export const sessionIsLoading = (state: SessionState): boolean => {
    return [LoadingState.PENDING, LoadingState.LOADING].includes(state.loadingStatus)
}

export const sessionLoadingFailed = (state: SessionState): boolean => {
    return state.loadingStatus === LoadingState.FAILED
}

export const sessionIsLoaded = (state: SessionState): boolean => {
    return state.loadingStatus === LoadingState.LOADED
}

export const getSessionParams = (state: SessionState): SessionParams => state.params

export const getAuthToken = (state: SessionState): string => state.authToken

export const getAddressCustomerForm = (state: SessionState): CustomerFormAddress => ({
    firstName: state.data.firstName,
    lastName: state.data.lastName,
    country: state.data.country,
    stateProvince: state.data.stateProvince,
    postalCode: state.data.postalCode,
})

export const getMakePrimaryCard = (state: SessionState): boolean => state.data.makePrimaryCard

export const getFirstName = (state: SessionState): string => state.data.firstName

export const getLastName = (state: SessionState): string => state.data.lastName

export const getFullName = (state: SessionState): string => composeFullName(state.data)

export const getCountry = (state: SessionState): string => state.data.country

export const getStateProvince = (state: SessionState): string => state.data.stateProvince

export const getPostalCode = (state: SessionState): string => state.data.postalCode

export const getPaymentMethodId = (state: SessionState): string => state.data.paymentMethodId

export const getLocalError = (state: SessionState): string => state.localError

export const getFlow = (state: SessionState): Flow => state.flow
