import {FC} from "react"
import {FormGroup, FormLabel} from "@malwarebytes/react-components/build/form"
import {Dropdown} from "@malwarebytes/react-components/build/dropdown"
import {DropdownFieldProps} from "../../../../../types/form/controls"
import {ErrorBlock} from "../../error-block"

export const DropdownField: FC<DropdownFieldProps> = (props) => {

    return (
        <FormGroup className=''>
            { props.label && (
                <FormLabel className='w-100'>
                    {props.label}
                </FormLabel>
            )}
            <Dropdown {...props}>
                <Dropdown.Toggle/>
                <Dropdown.Menu>
                    {props.options.map(c => (
                        <Dropdown.Item key={c.id} eventKey={c.id} eventLabel={c.name}>
                            {c.name}
                        </Dropdown.Item>
                    ))}
                    {props.children}
                </Dropdown.Menu>
            </Dropdown>
            {props.error && <ErrorBlock error={props.error} />}
        </FormGroup>
    )
}
