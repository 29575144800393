import {ComponentType, useEffect, useRef} from 'react'
import {AddressData} from "../../../components/customer/address-data"
import {Formik} from 'formik'
import {Form} from "@malwarebytes/react-components/build/form"
import {useCustomerValidationSchema} from "./hooks/validation/use-customer-validation-schema"
import {ChaseInvisibleForm} from "../../../components/customer/chase/invisible-form"
import {useSubmit} from "./hooks/submit/use-submit"
import {useInitialValues} from "./hooks/use-initial-values"
import {BorderlessCard} from "../../../components/general/blocks/borderless-card"
import {CustomerLoader} from "../../../components/customer/blocks/loader"
import {ErrorMessage} from "../../../components/customer/blocks/error-message"
import {submitDisabled} from "./helpers/submit-disabled"
import {withActivityTracking} from "./with-activity-tracking"
import {FormTitle} from "../../../components/general/blocks/form-title"
import {CustomerHeader} from "../../../components/customer/blocks/customer-header"
import {SubmitBlock} from "../../../components/general/blocks/buttons/submit-block"
import {ExtraData} from "../../../components/customer/extra-data"
import {CardSwitch} from "../../../components/customer/card/switch/card-switch"
import {useChaseFormStatus} from "./hooks/use-chase-form-status"

export const Customer: ComponentType = withActivityTracking(() => {
    const validationSchema = useCustomerValidationSchema()

    const chaseForm = useRef<HTMLFormElement>(null)

    const initialValues = useInitialValues()

    const onSubmit = useSubmit(chaseForm)

    const chaseFormStatus = useChaseFormStatus()

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({handleSubmit, dirty, isValid, isSubmitting, errors}) => (
            <>
                <CustomerLoader display={isSubmitting} />
                <div>
                    <CustomerHeader />
                    <BorderlessCard>
                        <ErrorMessage />

                        <FormTitle />

                        <AddressData />

                        <CardSwitch/>

                        <ExtraData />
                    </BorderlessCard>

                    <SubmitBlock
                        onClick={() => handleSubmit()}
                        submitDisabled={submitDisabled({
                            dirty,
                            isValid,
                            isSubmitting,
                            chaseFormStatus
                        })}
                    />
                </div>
            </>
            )}
        </Formik>
    )
})
