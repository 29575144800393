import {useTranslation} from "react-i18next"
import {Loader} from "../../../../components/general/loader/loader"
import {useCollectChaseStatus} from "./hooks/use-collect-chase-status"
import {useCollectPaymentMethod} from "./hooks/use-collect-payment-method"

export const ProcessingChaseNeo = () => {
    const { t } = useTranslation()

    const authorizationSuccess = useCollectChaseStatus()
    useCollectPaymentMethod({authorizationSuccess})

    return (
        <Loader
            transparent={false}
            header={t('loader.header.processing')}
            text={t('loader.text.processing')}
        />
    )
}