import {ValidationFn} from "../../types"
import cardValidator from "card-validator"
import {toYupTest} from "../../helpers/to-yup-test"
import i18n from "../../../../i18n/index"
import {TestConfig} from "yup"

export const creditCardNumber = (): ValidationFn<string> => (value) => {
    const card = cardValidator.number(value)

    return card.isValid
}

export const asYupTest = (): TestConfig<string | undefined> => toYupTest({
    name: 'creditCardNumber',
    message: i18n.t('validation.card.number.valid'),
    validationFn: creditCardNumber()
})
