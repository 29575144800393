import {ComponentType, useEffect} from "react"
import {useLocation} from "react-router-dom"
import {emitPageView} from "../../../services/analytics"
import {pagePath} from '../../../services/utility/routes/helpers'
import {routes} from '../../../config/routes/routes.config'

export const withPageTracking = <P,>(Component: ComponentType<P>): ComponentType<P> => {
    return (props: P) => {
        const location = useLocation()

        useEffect(() => {
            emitPageView(pagePath(routes.session, location.pathname))
        }, [location])

        return <Component {...props} />
    }
}