import {ServerProvidedClient, UseServerProvidedObservableConfig} from "../../types/types"
import {useEffect, useState} from "react"
import {EMPTY, Observable} from "rxjs"
import {createServerProvided} from "../../observables/server-provided/create-server-provided"

export const useServerProvidedObservable = <ST, RT = ST>(
    client: ServerProvidedClient<ST>,
    config: UseServerProvidedObservableConfig<ST, RT>,
): Observable<RT> => {
    const [observable$, setObservable] = useState<Observable<RT>>(EMPTY)

    const {
        channelName,
        eventName,
        fallbackTo
    } = config

    useEffect(() => {
        if(!channelName || !eventName) {
            setObservable(EMPTY)
            return
        }

        setObservable(createServerProvided(
            client,
            {
                ...config,
                channelName
            }
        ))
    }, [client, channelName, eventName, fallbackTo])

    return observable$
}
