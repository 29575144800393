import {thunk as fetchSession, reducers as fetchSessionReducers} from './fetch-session'
import {thunk as persistAddress, reducers as persistAddressReducers} from './persist-address'
import {thunk as savePrimaryCardFlag, reducers as savePrimaryCardFlagReducers} from './save-primary-card-flag'
import {thunk as updatePaymentProvider, reducers as updatePaymentProviderReducers} from './update-payment-provider'

export const thunks = {
    fetchSession,
    persistAddress,
    savePrimaryCardFlag,
    updatePaymentProvider,
}

export const extraReducers = {
    ...fetchSessionReducers,
    ...persistAddressReducers,
    ...updatePaymentProviderReducers,
    ...savePrimaryCardFlagReducers
}
