type ReactBoostrapFormValue = string | number | string[] | undefined

type CreditCardNumberValue = string | undefined

export const narrowToString = (value: ReactBoostrapFormValue): CreditCardNumberValue => {
    if(typeof value === 'number' || Array.isArray(value)) {
        return value.toString()
    }

    return value
}
