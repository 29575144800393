import {ComponentType, useEffect} from "react"
import {useActivityTrackerWithConfig} from "./hooks/activity/use-activity-tracker"
import {sendToParent} from "../../../../services/iframe/send-to-parent"
import {IframeEvent} from "../../../../config/iframe/iframe-event"

export const withActivityTracking = <P,>(Component: ComponentType<P>): ComponentType<P> => {
    return (props: P) => {

        const tracker$ = useActivityTrackerWithConfig()

        useEffect(() => {
            const subscription = tracker$.subscribe({
                next: () => sendToParent(IframeEvent.USER_ACTIVITY_TRACKED)
            })

            return () => {
                subscription.unsubscribe()
            }
        }, [tracker$])

        return <Component {...props} />
    }
}
