import {createAsyncThunk} from "@reduxjs/toolkit"
import {SessionState, UPDATE_PAYMENT_PROVIDER_THUNK} from "../types"
import {ThunkApiConfig} from "../../../store"
import {updatePaymentProvider} from "../../../../api/lira/lira"
import {DefaultExtraReducersTree} from "../../../../types/store"
import {SessionData} from "../../../../api/lira/types"
import {PaymentProvider} from "../../../../types/payment-provider"

const thunk = createAsyncThunk<SessionData, PaymentProvider, ThunkApiConfig>(
    UPDATE_PAYMENT_PROVIDER_THUNK,
    (paymentProvider) => {
        return updatePaymentProvider({paymentProvider})
    }
)

const reducers: DefaultExtraReducersTree<SessionState, SessionData> = {}

export {
    thunk,
    reducers
}
