import {PaymentProvider} from "../../types/payment-provider"
import i18n from "i18next"
import {ErrorMessages} from "../../types/config.types"

export const messages: ErrorMessages = {
    [PaymentProvider.AUTH_NET]: [
        {
            codes: ['2', '3', '4'],
            message: i18n.t('paymentErrors.cardIssuerDeclinedPayment'),
        },
        {
            codes: ['6', '8'],
            message: i18n.t('paymentErrors.errorProcessingPayment'),
        },
        {
            codes: ['20'],
            message: i18n.t('paymentErrors.errorProcessingOrder'),
        },
        {
            codes: ['27', '45'],
            message: i18n.t('paymentErrors.paymentCouldNotBeProcessed'),
        },
        {
            codes: ['30', '65', '13', '57'],
            message: i18n.t('paymentErrors.paymentMethodDeclined'),
        },
        {
            codes: ['37', '44'],
            message: i18n.t('paymentErrors.errorProcessingPayment'),
        },
    ],
    [PaymentProvider.CHASE_DHPP]: [
        {
            codes: [ '100.380.401', '800.100.172', '800.100.192', '600.200.100', '600.200.400', '100.500.201',
                '100.100.501'],
            message: i18n.t('paymentErrors.paymentMethodDeclined'),
        },
        {
            codes: ['100.390.112', '800.100.157', '800.100.153', '100.100.101', '100.100.303', '800.900.401'],
            message: i18n.t('paymentErrors.errorProcessingPayment')
        },
        {
            codes: ['800.100.155', '800.100.152', '800.100.162', '800.100.163', '800.100.171', '800.100.159',
                '800.100.158', '800.100.151', '800.100.165', '800.100.168', '800.100.171', '800.100.169', '800.400.200'
            ],
            message: i18n.t('paymentErrors.cardIssuerDeclinedPayment'),
        },
        {
            codes: ['800.400.100', '000.400.030', '800.700.101', '800.700.201', '800.700.500', '800.800.102',
                '800.800.202', '800.800.302', '800.900.101', '800.900.200', '800.400.110'],
            message: i18n.t('paymentErrors.paymentCouldNotBeProcessed'),
        },
        {
            codes: ['800.100.160'],
            message: i18n.t('paymentErrors.couldNotProcessOrder'),
        },
        {
            codes: ['500.100.304'],
            message: i18n.t('paymentErrors.errorProcessingOrder'),
        },
    ],
}
