import {VFC} from "react"
import {Loader} from "../../general/loader/loader"
import {useTranslation} from "react-i18next"

type CustomerLoaderProps = {
    display: boolean
}

export const CustomerLoader: VFC<CustomerLoaderProps> = ({display}) => {
    const { t } = useTranslation()

    return display
        ? <Loader
            transparent={true}
            header={t('loader.header.processing')}
            text={t('loader.text.processing')}
        />
        : null
}
