import {PaymentProvider} from "../../../../types/payment-provider"
import {ProvidersMapping, providersMapping} from "../../../../config/payment/providers-mapping"

export const determine = (
    mapping: ProvidersMapping
) => (country: string | undefined): Nullable<PaymentProvider> => {
    if(!country) {
        return null
    }

    return mapping.countries[country] || mapping.default
}

const determineWithConfig = determine(providersMapping)

export {
    determineWithConfig as determinePaymentProvider
}
