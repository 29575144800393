import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import {logger} from './middleware'
import {reducer as session} from './slices/session'
import {reducer as card} from './slices/card'
import {analyticsMiddleware} from './middleware/analytics-middleware'

export const store = configureStore({
  reducer: {
    card,
    session,
  },
  middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
          .prepend(analyticsMiddleware)
          // .concat(logger)
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
export type ThunkApiConfig = {
  dispatch: AppDispatch,
  state: RootState
}
