import {useEffect, useState} from "react"

export enum ScriptStatus {
    IDLE = 'idle',
    LOADING = 'loading',
    READY = 'ready',
    ERROR = 'error',
}

export const useExternalScript = (src: string): ScriptStatus => {
    const [status, setStatus] = useState<ScriptStatus>(
        src ? ScriptStatus.LOADING : ScriptStatus.IDLE
    )

    useEffect(() => {
        if(!src){
            setStatus(ScriptStatus.IDLE)
            return
        }

        let script = document.querySelector<HTMLScriptElement>(`script[src="${src}"]`)
        if(!script) {
            script = document.createElement('script')
            script.src = src
            script.async = true
            script.setAttribute('data-status', 'loading')
            document.body.appendChild(script)

            const setAttributeFromEvent = (event: Event): void => {
                if(script) {
                    script.setAttribute(
                        'data-status',
                        event.type === 'load'
                            ? 'ready'
                            : 'error'
                    )
                }
            }

            script.addEventListener('load', setAttributeFromEvent)
            script.addEventListener('error', setAttributeFromEvent)
        }else{
            setStatus(script.getAttribute('data-status') as ScriptStatus)
        }

        const setStateFromEvent = (event: Event): void => {
            setStatus(event.type === 'load' ? ScriptStatus.READY : ScriptStatus.ERROR)
        }

        script.addEventListener('load', setStateFromEvent)
        script.addEventListener('error', setStateFromEvent)

        return () => {
            if(script) {
                script.removeEventListener('load', setStateFromEvent)
                script.removeEventListener('error', setStateFromEvent)
            }
        }

    }, [src])

    return status
}
