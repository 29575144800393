import {useEffect} from "react"
import {CustomerForm} from "../../../../../../types/forms"
import {useFormikContext} from "formik"

export const useTruncateDependenciesOnChange = (country: string): void => {
    const {
        setFieldValue,
        setFieldTouched,
    } = useFormikContext<CustomerForm>()

    useEffect(() => {
        return () => {
            if(country) {
                setFieldTouched('address.stateProvince', true, true)
                setFieldTouched('address.postalCode', true, true)
                setFieldValue('address.stateProvince', '', true)
            }
        }
    }, [country])
}
