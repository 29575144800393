import {ErrorWithMessage} from "./error-with-message"
import i18n from "i18next"

export const composeErrorMessage = (error: unknown): string => {
    const fallbackMessage = i18n.t('paymentErrors.generic')

    if(error instanceof ErrorWithMessage) {
        return error.getPublicMessage() || fallbackMessage
    }

    return fallbackMessage
}
