import {CardState, sliceName} from "./types"
import {createSlice} from "@reduxjs/toolkit"
import {reducers} from "./reducers"

export const initialState: CardState = {
    data: {
        number: '',
        expirationDate: '',
        verificationNumber: ''
    },
    type: null,
    paymentProvider: null,
    chaseToken: '',
}

const {
    reducer,
    actions: actionCreators,
} = createSlice({
    name: sliceName,
    initialState,
    reducers,
    extraReducers: {}
})

export {
    reducer,
    actionCreators,
}

export * from './types'
