import {useEffect, useState, VFC} from "react"
import styled from "styled-components"
import {CreditCardType} from "../../../../../../../../types/credit-card"
import amExImage from "../images/amex.png"
import defaultImage from "../images/visa.png"

const PopoverImage = styled.img`
  margin: auto;
  padding: 0;
  max-width: 200px;
`

const imageMap: Partial<Record<CreditCardType, string>> = {
    [CreditCardType.AMEX]: amExImage,
}

const getCardImage = (type: Nullable<CreditCardType>): string => {
    if(!type) {
        return defaultImage
    }

    return imageMap[type] || defaultImage
}

export const OverlayImage: VFC<{ cardType: Nullable<CreditCardType> }> = ({cardType}) => {
    const [img, setImg] = useState(defaultImage)

    useEffect(() => {
        setImg(getCardImage(cardType))
    }, [cardType])

    return <PopoverImage
        src={img}
    />
}
