import {SessionState} from "./types"
import {PayloadAction} from "@reduxjs/toolkit"
import {CustomerFormAddress} from "../../../types/forms"

type SessionParamsUndefinedable = { id: string | undefined, pwd: string | undefined }

const setSessionParams = (state: SessionState, action: PayloadAction<SessionParamsUndefinedable>): void => {
    state.params.id = action.payload.id || ''
    state.params.pwd = action.payload.pwd || ''

    const {id, pwd} = state.params

    state.authToken = id && pwd
        ? btoa(`${id}:${atob(pwd)}`)
        : ''
}

const setAddressCustomerForm = (state: SessionState, action: PayloadAction<CustomerFormAddress>): void => {
    state.data.firstName = action.payload.firstName
    state.data.lastName = action.payload.lastName
    state.data.country = action.payload.country
    state.data.stateProvince = action.payload.stateProvince
    state.data.postalCode = action.payload.postalCode
}

const setPrimaryCardFlag = (state: SessionState, action: PayloadAction<boolean>): void => {
    state.data.makePrimaryCard = action.payload
}

const setFirstName = (state: SessionState, action: PayloadAction<string>): void => {
    state.data.firstName = action.payload
}

const setLastName = (state: SessionState, action: PayloadAction<string>): void => {
    state.data.lastName = action.payload
}

const setCountry = (state: SessionState, action: PayloadAction<string>): void => {
    state.data.country = action.payload
}

const setStateProvince = (state: SessionState, action: PayloadAction<string>): void => {
    state.data.stateProvince = action.payload
}

const setPostalCode = (state: SessionState, action: PayloadAction<string>): void => {
    state.data.postalCode = action.payload
}

const setPaymentMethodId = (state: SessionState, action: PayloadAction<string>): void => {
    state.data.paymentMethodId = action.payload
}

const setLocalError = (state: SessionState, action: PayloadAction<string>): void => {
    state.localError = action.payload
}

const truncateLocalError = (state: SessionState): void => {
    state.localError = ''
}

export const reducers = {
    setSessionParams,
    setAddressCustomerForm,
    setPrimaryCardFlag,
    setFirstName,
    setLastName,
    setCountry,
    setStateProvince,
    setPostalCode,
    setPaymentMethodId,
    setLocalError,
    truncateLocalError,
}
