import {PaymentProvider} from "../../../../../../../types/payment-provider"
import {RefObject} from "react"
import {useAuthNetSubmit} from "./auth-net/use-auth-net-submit"
import {useChaseSubmit} from "./chase/use-chase-submit"
import {CustomerForm} from "../../../../../../../types/forms"
import {useChaseSubmitNeo} from "./chase/use-chase-submit-neo"

type ProviderSubmitFn = (cardData: CustomerForm) => Promise<void>

export const useProviderSubmit = (
    provider: Nullable<PaymentProvider>,
    chaseForm: RefObject<HTMLFormElement>
): Nullable<ProviderSubmitFn> => {
    const submitFns = {
        [PaymentProvider.AUTH_NET]: useAuthNetSubmit(),
        // [PaymentProvider.CHASE_DHPP]: useChaseSubmit(chaseForm),
        [PaymentProvider.CHASE_DHPP]: useChaseSubmitNeo(),
    }

    return provider && submitFns[provider]
}
