import {VFC} from "react"
import {Header} from "../../general/blocks/header"
import {useTranslation} from "react-i18next"
import {useSessionStateSelector} from "../../../../store/slices/session/hooks"
import {getFlow} from "../../../../store/slices/session/selectors"
import {Flow} from "../../../../types/flow"

export const CustomerHeader: VFC = () => {
    const {t} = useTranslation()

    const flow = useSessionStateSelector(state => getFlow(state))

    const text = flow === Flow.REPLACE_CARD ? t('topBanner.textReplace') : t('topBanner.textCreate')

    return <Header text={text} />
}
