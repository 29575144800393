import {CreditCardType} from "../../../../types/credit-card"
import {VFC} from "react"
import visaImage from "./images/visa.svg"
import mastercardImage from "./images/mastercard.svg"
import maestroImage from "./images/maestro.svg"
import discoverImage from "./images/discover.svg"
import amExImage from "./images/american-express.svg"
import jcbImage from "./images/jcb.svg"
import {Logo} from "./logo"

type CardLogosProps = {
    display: CreditCardType[],
    inColor: CreditCardType[],
    height: number
}

const imageMap: Record<CreditCardType, string> = {
    [CreditCardType.VISA]: visaImage,
    [CreditCardType.MASTERCARD]: mastercardImage,
    [CreditCardType.MAESTRO]: maestroImage,
    [CreditCardType.DISCOVER]: discoverImage,
    [CreditCardType.AMEX]: amExImage,
    [CreditCardType.JCB]: jcbImage,
}

export const CardLogos: VFC<CardLogosProps> = ({display, inColor, height}) => {
    return display.length > 0 ? (
        <div>
            {display.map(c => (
                <Logo
                    key={c}
                    src={imageMap[c]}
                    height={height}
                    active={inColor.includes(c)}
                />
            ))}
        </div>
    ) : null
}
