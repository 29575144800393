import {debounceTime, EMPTY, fromEvent, Observable} from "rxjs"
import {useEffect, useState} from "react"
import {UseActivityProps} from "../../types/types"

export const useClicksTracker = ({debounceWithin}: UseActivityProps): Observable<Event> => {
    const [observable$, setObservable] = useState<Observable<Event>>(EMPTY)

    useEffect(() => {
        setObservable(
            fromEvent(document, 'click')
                .pipe(
                    debounceTime(debounceWithin)
                )
        )
    }, [])

    return observable$
}
