import {forwardRef} from "react"
import {useCardStateSelector} from "../../../../../../../../store/slices/card/hooks"
import {getCardType} from "../../../../../../../../store/slices/card/selectors"
import {Popover} from "@malwarebytes/react-components/build"
import {OverlayText} from "./text"
import {OverlayImage} from "./image"

export const VerificationOverlay = forwardRef<HTMLDivElement>((props, ref) => {
    const cardType = useCardStateSelector(state => getCardType(state))

    return (
        <Popover {...props} ref={ref}>
            <Popover.Body>
                <OverlayText cardType={cardType} />
                <OverlayImage cardType={cardType} />
            </Popover.Body>
        </Popover>
    )
})
