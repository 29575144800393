import {VFC} from "react"
import {useTranslation} from "react-i18next"
import {Banner} from "../banner"
import {FullWidthButton} from "./full-width-button"

type SubmitBlockProps = {
    submitDisabled: boolean,
    onClick: VoidFunction
}

export const SubmitBlock: VFC<SubmitBlockProps> = ({submitDisabled, onClick}) => {
    const {t} = useTranslation()

    return (
        <Banner type="secondary">
            <FullWidthButton
                type="submit"
                disabled={submitDisabled}
                onClick={onClick}
            >
                {t('buttons.submit')}
            </FullWidthButton>
        </Banner>
    )
}
