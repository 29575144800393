import {VFC} from "react"
import {Row} from "@malwarebytes/react-components/build/row"
import {InputField} from "../../form/controls/input-field"
import {useFormikContext} from "formik"
import {CustomerForm} from "../../../../types/forms"
import {Country} from "./country/country"
import {Region} from "./region/region"
import {useTranslation} from "react-i18next"
import {Col} from "@malwarebytes/react-components"

export const AddressData: VFC = () => {
    const { t } = useTranslation()

    const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
    } = useFormikContext<CustomerForm>()

    return (
        <div className='px-3'>
            <Row className='pb-3'>
                <Country
                    value={values.address.country}
                    error={touched.address?.country ? errors.address?.country : null}
                />
            </Row>
            <Row className='pb-3'>
                <Col xs={12} sm={6} className='pb-3 pb-sm-0'>
                    <Region
                        country={values.address.country}
                        value={values.address.stateProvince}
                        error={touched.address?.stateProvince ? errors.address?.stateProvince : null}
                    />
                </Col>
                <Col>
                    <InputField
                        label={t('form.address.postalCode')}
                        name="address.postalCode"
                        value={values.address.postalCode}
                        error={touched.address?.postalCode ? errors.address?.postalCode : null}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Col>
            </Row>
        </div>
    )
}
