import {LoadingState} from "../../../types/store"
import {Flow} from "../../../types/flow"

export const sliceName = 'session'

export const FETCH_SESSION_THUNK = sliceName + '/fetchSession'
export const PERSIST_ADDRESS_THUNK = sliceName + '/persistAddress'
export const SAVE_PRIMARY_CARD_FLAG_THUNK = sliceName + '/savePrimaryCardFlag'
export const UPDATE_PAYMENT_PROVIDER_THUNK = sliceName + '/updatePaymentProvider'

export type SessionParams = {
    id: string,
    pwd: string,
}

export type SessionState = {
    loadingStatus: LoadingState,
    params: SessionParams,
    authToken: string,
    data: {
        firstName: string,
        lastName: string,
        country: string,
        stateProvince: string,
        postalCode: string,
        paymentMethodId: string,
        makePrimaryCard: boolean
    },
    flow: Flow,
    localError: string
}
