export class PassthroughComposer {
    params: Array<Record<string, string | number>>

    constructor () {
        this.params = []
    }

    addParam(key: string, value: string) {
        this.params.push({key, value})

        return this
    }

    compose() {
        const result = this.params
            .map(({key, value}) => `${key}=${value}`)
            .join('&')

        return result ? result : null
    }

}
