import {AuthorizationResult} from "../types/types"
import {useEffect} from "react"
import {useProceed} from "../../hooks/use-proceed"
import {getErrorMessageWithConfig} from "../../helpers/get-error-message"
import {PaymentProvider} from "../../../../../../types/payment-provider"
import {ErrorWithMessage} from "../../../../../../errors/error-with-message"

export const useUnsuccessfulAuthorization = (authorizationResult: Optional<AuthorizationResult>): void => {
    const {error} = useProceed()

    useEffect(() => {
        if(!authorizationResult || authorizationResult.success) {
            return
        }

        console.error('Authorization error', { authorizationResult })

        const m = getErrorMessageWithConfig(PaymentProvider.CHASE_DHPP, authorizationResult.statusCode)

        error(new ErrorWithMessage(m))
    }, [authorizationResult, error])
}
