import {useProcessingPoller} from "../../hooks/use-processing-poller"
import {useProceed} from "../../hooks/use-proceed"
import {useEffect} from "react"
import {closeSubscription} from "../../../../../../services/realtime/helpers/close-subscription"

type Props = {
    authorizationSuccess: boolean
}

export const useCollectPaymentMethod = ({authorizationSuccess}: Props) => {
    const poller$ = useProcessingPoller()

    const {success, error} = useProceed()

    useEffect(() => {
        if (!authorizationSuccess) {
            return
        }

        const subscription = poller$.subscribe({
            next: ({paymentMethodId}) => success(paymentMethodId),
            error: err => {
                console.error('Polling error', err)
                error(err)
            }
        })

        return () => closeSubscription(subscription)
    }, [poller$, authorizationSuccess, success, error])
}