import {ComponentType} from "react"
import {useFormikContext} from "formik"
import {CustomerForm} from "../../../../../types/forms"

type NameAwareProps<P> = P & {
    name: string
}

export const withFormikHandles = <P,>(
    Component: ComponentType<NameAwareProps<P>>
): ComponentType<NameAwareProps<P>> => {
    return (props: NameAwareProps<P>) => {
        const {
            setFieldValue,
            setFieldTouched,
        } = useFormikContext<CustomerForm>()

        const handleClose = () => process.nextTick(() => setFieldTouched(props.name, true, true))
        const handleSelect = (value: string | null) => process.nextTick(() => {
            setFieldValue(props.name, value, true)
        })

        return <Component {...props} onClose={handleClose} onSelect={handleSelect}  />
    }
}
