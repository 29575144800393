import {ComponentType} from "react"
import {useParams} from "react-router-dom"
import {useProcessingReceiver} from "./hooks/use-processing-receiver"
import {useProceed} from "../hooks/use-proceed"
import {closeSubscription} from "../../../../../services/realtime/helpers/close-subscription"
import {useSessionLoaded} from "../../../../../hooks/session/use-session-loaded"

export const withProcessingSetup = <P,>(Component: ComponentType<P>): ComponentType<P> => {
    return (props: P) => {
        const {success, error} = useProceed()

        const {collectorId} = useParams()

        const receiver$ = useProcessingReceiver(collectorId)

        useSessionLoaded(() => {
            const subscription = receiver$.subscribe({
                next: ({paymentMethodId}) => {
                    subscription.unsubscribe()

                    success(paymentMethodId)
                },
                error: e => error(e)
            })

            return () => closeSubscription(subscription)
        }, [receiver$])

        return <Component {...props} />
    }
}
