import {Loader} from "../../../../components/general/loader/loader"
import {useTranslation} from "react-i18next"
import {withProcessingSetup} from "./with-processing-setup"

export const AuthNetProcessing = withProcessingSetup(() => {
    const {t} = useTranslation()

    return (
        <Loader
            transparent={false}
            header={t('loader.header.processing')}
            text={t('loader.text.processing')}
        />
    )
})
