import {VFC} from "react"
import {useTranslation} from "react-i18next"
import {Header} from "../../components/general/blocks/header"
import {BorderlessCard} from "../../components/general/blocks/borderless-card"
import {Row} from "@malwarebytes/react-components/build/row"
import {CatchPhrase} from "../../components/errors/blocks/catch-phrase"
import {ErrorMessage} from "../../components/errors/blocks/error-message"
import {Spacer} from "../../components/errors/blocks/spacer"
import styled from 'styled-components'
import {CloseBlock} from "../../components/general/blocks/buttons/close-block"
import {useSessionStateSelector} from '../../../store/slices/session/hooks'
import {getFlow} from '../../../store/slices/session/selectors'
import {Flow} from '../../../types/flow'

const CardFailureSvg = styled.img`
  content: url("${p => p.theme.svg.cardFailure}");
  margin: auto;
  width: 232px;
  height: 173px;
`

export const SomethingWentWrong: VFC = () => {
    const {t} = useTranslation()

    const flow = useSessionStateSelector(state => getFlow(state))

    const text = flow === Flow.REPLACE_CARD ? t('topBanner.textReplace') : t('topBanner.textCreate')

    return (
        <div>
            <Header text={text}/>
            <BorderlessCard>
                <Row className="my-5">
                    <CardFailureSvg />
                </Row>
                <CatchPhrase className="mb-3">
                    {t('somethingWentWrong.header')}
                </CatchPhrase>
                <ErrorMessage>
                    {t('somethingWentWrong.text')}
                </ErrorMessage>
                <Spacer height='107px' />
            </BorderlessCard>
            <CloseBlock />
        </div>
    )
}
