export type ScriptElementInput = {
    async?: boolean,
    src?: string,
    text?: string
}

export const performAppend = (element: HTMLScriptElement) => {
    document.body.appendChild(element)
}

export const performRemove = (input: HTMLScriptElement | HTMLScriptElement[]) => {
    if (Array.isArray(input)) {
        input.forEach(element => document.body.removeChild(element))
    } else {
        document.body.removeChild(input)
    }
}

export const composeScriptElement = ({async, src, text}: ScriptElementInput) => {
    const element = document.createElement('script')

    element.type = 'text/javascript'
    element.async = async || false
    if (src) {
        element.src = src
    }
    if (text) {
        element.text = text
    }

    return element
}

export const appendScriptElement = (input: ScriptElementInput) => {
    const element = composeScriptElement(input)

    performAppend(element)

    return element
}

export const appendScriptElements = (elements: ScriptElementInput[]) => {
    return elements.map(e => appendScriptElement(e))
}