import {FC, useEffect, useState} from "react"
import {ThemeProvider as StyledThemeProvider} from "styled-components"
import {useTheme} from "../../hooks/dom/use-theme"
import {themeToStyle} from "../../services/theme/theme-to-style"
import {BaseStyle} from "../../services/theme/styles/base-style"

export const ThemeProvider: FC = (props) => {
    const [theme] = useTheme()
    const [themeStyle, setThemeStyle] = useState<BaseStyle>(themeToStyle(theme))

    useEffect(() => {
        const style = {
            mode: theme,
            ...themeToStyle(theme),
        }
        setThemeStyle(style)
    }, [theme])

    return (
        <StyledThemeProvider theme={themeStyle}>
            {props.children}
        </StyledThemeProvider>
    )
}
