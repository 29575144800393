import {RouteParams, WithGetPath, WithPrefix} from "./types"
import {parseUrlQuery} from '../helpers/parse-url-query'
import {PassthroughComposer} from '../helpers/passthrough-composer'

export const pathTo = (...input: Array<WithPrefix>): string => {
    let buffer = ''

    for(const {prefix} of input) {
        buffer += prefix + '/'
    }

    if(buffer.charAt(buffer.length - 1) === '/') {
        buffer = buffer.slice(0, buffer.length - 1)
    }

    return buffer
}

export const linkTo = (piece: WithGetPath, params?: RouteParams): string => {
    let path = piece.getPath()

    if(params) {
        for(const [k, v] of Object.entries(params)) {
            path = path.replace(`:${k}`, v)
        }
    }

    const query = parseUrlQuery(window.location.search)
    const composer = new PassthroughComposer()
    for(const [k, v] of Object.entries(query)) {
        composer.addParam(k, v)
    }
    const queryString = composer.compose()

    return `${path}?${queryString}`
}

/**
 * Assumes first level of config object will contain Page definitions
 */
export const pagePath = (config: Record<string,any>, url: string): string => {
    for (const value of Object.values(config)) {
        if (typeof value === 'object') {
            const paths = value.prefix.split('/')
            const basePath = paths[0]

            if (url.includes(basePath)) {
                const path = pagePath(value, url)

                if (path !== url) {
                    // This is our base case. if the path returned from the recursion is different from the url we passed in
                    // that means it was able to find a `value.getPath()`  otherwise it would break out of the for loop
                    // and return just the url we passed in.
                    return path
                }

                return value.getPath()
            }
        }
    }

    return url
}
