import {BaseStyle} from "../base-style"
import unknown from "./icons/unknown-dark.svg"
import cardFailure from "./icons/card-failure-dark.svg"
import cardSuccess from "./icons/card-success-dark.svg"

export const DarkStyle: BaseStyle = {
    banner: {
        backgroundPrimary: '#222',
        backgroundSecondary: '#202228',
        text: '#3d94fd',
    },
    errorPage: {
        catchPhrase: '#6e8be0',
        text: '#dcdde0',
    },
    svg: {
        checkmark: '#6B8BED',
        cardFailure,
        cardSuccess,
        unknown,
    }
}