export class ErrorWithMessage extends Error {

    constructor(private readonly publicMessage: Empty<string>) {
        super()
    }

    public getPublicMessage(): Empty<string>
    {
        return this.publicMessage
    }
}
