import {VFC} from "react"
import {useTranslation} from "react-i18next"
import {Col, Form, Row} from "@malwarebytes/react-components"
import {useFormikContext} from "formik"
import {CustomerForm} from "../../../../types/forms"

export const ExtraData: VFC = () => {
    const { t } = useTranslation()

    const {
        values,
        handleChange,
    } = useFormikContext<CustomerForm>()

    return (
        <div className='px-3'>
            <Row>
                <Col>
                    <Form.Check
                        type="checkbox"
                        name="extra.makePrimary"
                        label={t('form.extra.makePrimaryCard')}
                        checked={values.extra.makePrimary}
                        onChange={handleChange}
                        />
                </Col>
            </Row>
        </div>
    )
}
