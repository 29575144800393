import {useSessionStateSelector} from "../../store/slices/session/hooks"
import {getLoadingStatus} from "../../store/slices/session/selectors"
import {useEffect} from "react"
import {LoadingState} from "../../types/store"

type CallbackFn = () => void

export const useSessionLoaded = (
    callback: CallbackFn,
    extraDeps: unknown[] = []
): void => {
    const loadingStatus = useSessionStateSelector(state => getLoadingStatus(state))

    useEffect(() => {
        if(loadingStatus === LoadingState.LOADED) {
            return callback()
        }
    }, [
        loadingStatus,
        ...extraDeps
    ])
}
