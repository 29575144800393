import {useEffect, useState, VFC} from "react"
import {useTranslation} from "react-i18next"
import {DropdownFieldOptions} from "../../../../../types/form/controls"
import {composeRegionOptions} from "../helpers/compose-region-options"
import {FormikDropdownField as DropdownField} from "../../../form/controls/dropdown-field/formik-dropdown-field"

type RegionProps = {
    country: string,
    value: string,
    error: string | null | undefined,
}

export const Region: VFC<RegionProps> = (props) => {
    const { country } = props

    const { t } = useTranslation()

    const [options, setOptions] = useState<DropdownFieldOptions>([])
    const [value, setValue] = useState('')

    useEffect(() => {
        return setOptions(composeRegionOptions(country))
    }, [country])

    useEffect(() => {
        //TODO: Find cleaner solution, https://malwarebytes.atlassian.net/browse/BONP-1870
        //SalesForce sends shortCode which blows up initial value. So lets attempt to get the correct value
        const value = options.find((o) => o.id === props.value )
        setValue((value?.name || props.value) as string)
    }, [props.value])

    return (
        <DropdownField
            {...props}
            value={value}
            label={t('form.address.stateProvince')}
            name="address.stateProvince"
            options={options}
            disabled={!!props.country && !options.length}
            searchable={true}
        />
    )
}
