import {createAsyncThunk} from "@reduxjs/toolkit"
import {SAVE_PRIMARY_CARD_FLAG_THUNK, SessionState} from "../types"
import {ThunkApiConfig} from "../../../store"
import {savePrimaryCardFlag} from "../../../../api/lira/lira"
import {
    getMakePrimaryCard,
    getSessionState,
} from "../selectors"
import {DefaultExtraReducersTree} from "../../../../types/store"
import {SessionData} from "../../../../api/lira/types"

const thunk = createAsyncThunk<SessionData, void, ThunkApiConfig>(
    SAVE_PRIMARY_CARD_FLAG_THUNK,
    (_, thunkAPI) => {
        const state = getSessionState(thunkAPI.getState())

        return savePrimaryCardFlag({
            makePrimaryCard: getMakePrimaryCard(state)
        })
    }
)

const reducers: DefaultExtraReducersTree<SessionState, SessionData> = {}

export {
    thunk,
    reducers
}
