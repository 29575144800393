import {useCardStateSelector} from "../../../../../store/slices/card/hooks"
import {getCardType} from "../../../../../store/slices/card/selectors"
import {cardNumberMask} from "../../../../../services/card/mask/number-mask"

export const useCardNumberMask = (): any => {
    const cardType = useCardStateSelector(state => getCardType(state))

    return {
        maskPlaceholder: null,
        mask: cardNumberMask(cardType),
        beforeMaskedStateChange: ({previousState, currentState, nextState}
                                      : {previousState: unknown, currentState: unknown, nextState: unknown}) => {
            /**
             * TODO: Clean up typings
             * This is to handle https://malwarebytes.atlassian.net/browse/BONP-1867
             * There is a known bug in the masking library we use for autofill: https://github.com/sanniassin/react-input-mask
             * The recommended fix is to return the same value that is submitted with autofill
             * If Chrome seems autofill value with masking it'll blow up and incorrect values will show up
             *
             * 1st conditional checks if the "enteredString" chrome submits is equal to 15/16 for amex and restCards length
             *          TRUE --> Set the value to enteredString because that will give Chrome the exact match it needs
             *
             * 2nd conditional checks whether chrome freaked out and entered in empty string.
             *      TRUE ---> set value to the currentState because that will be Chrome's previous entry
             */
            let value = ''
            const {enteredString, value: nextValue} = (nextState as {enteredString: string, value: string})
            if (enteredString && (enteredString.length === 16 || enteredString.length === 15)) {
                value = enteredString
            }
            else if (enteredString === '' && nextValue) {
                value = (currentState as any).value
            }

            return {
                ...nextState as any,
                value: value || nextValue
            }
        }
    }
}