import {CustomerForm} from "../../../../../../types/forms"
import {actionCreators, thunks} from "../../../../../../store/slices/session"
import {useAppDispatch} from "../../../../../../store/hooks/use-app-dispatch"
import {RefObject} from "react"
import {getPaymentProvider} from "../../../../../../store/slices/card/selectors"
import {useProviderSubmit} from "./provider/use-provider-submit"
import {useCardStateSelector} from "../../../../../../store/slices/card/hooks"
import {sanitizeInput} from "../../helpers/sanitize-input"
import {composeErrorMessage} from "../../../../../../errors/compose-error-message"
import {emitActionEvent} from '../../../../../../services/analytics'

type UseSubmitResult = (values: CustomerForm) => Promise<void>

export type ProviderSubmitFn = (cardData: CustomerForm) => Promise<void>

export const useSubmit = (chaseForm: RefObject<HTMLFormElement>): UseSubmitResult => {
    const provider = useCardStateSelector(state => getPaymentProvider(state))

    const dispatch = useAppDispatch()

    const providerSubmit = useProviderSubmit(provider, chaseForm)

    return async (formValues: CustomerForm) => {
        const input = sanitizeInput(formValues)

        dispatch(actionCreators.truncateLocalError())

        dispatch(actionCreators.setAddressCustomerForm(input.address))
        dispatch(actionCreators.setPrimaryCardFlag(input.extra.makePrimary))

        await Promise.all([
            dispatch(thunks.persistAddress()).unwrap(),
            dispatch(thunks.savePrimaryCardFlag()).unwrap()
        ])

        if(!providerSubmit) {
            throw new Error('Provider-specific submit function not found')
        }

        try {
            emitActionEvent('Payment Method Submitted', {provider})
            await providerSubmit(input)
        }catch (e) {
            emitActionEvent('Payment Method Failed', {provider})
            dispatch(
                actionCreators.setLocalError(composeErrorMessage(e))
            )
        }
    }
}
