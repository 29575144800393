import {StrictMode, useEffect, useLayoutEffect, VFC} from "react"
import {StoreProvider} from "./store/store-provider"
import {AppRouter} from "./views/pages/app-router"
import {ThemeProvider} from './views/components/theme-provider'
import {sendToParent} from './services/iframe/send-to-parent'
import {IframeEvent} from './config/iframe/iframe-event'
import {debounce} from './services/utility/helpers/debounce'
import styled from "styled-components"
import {logger} from './services/log/logger'

const MAX_WIDTH = 624
const AppContainer = styled.div`
    max-width: ${() => `${MAX_WIDTH}px`}
`

export const App: VFC = () => {

    sendToParent(IframeEvent.ON_RESIZE, {
        width: String(MAX_WIDTH),
        height: '629',
    })

    useLayoutEffect(() => {
        const root = document.getElementById('app-container')
        const observer$ = new ResizeObserver(debounce((entries: any) => {
            let { width } = entries[0].contentRect
            const { height } = entries[0].contentRect

            if (!width || !height) {
                logger.debug('Received a 0 Dimension width: ', width, ' height: ', height)
                // don't send 0 size dimensions
                return
            }
            if (width > MAX_WIDTH) {
                width = MAX_WIDTH
            }

            sendToParent(IframeEvent.ON_RESIZE, {
                width: String(width),
                height: String(height),
            })
        }))
        observer$.observe(root as any)
    }, [])

  return (
      <StrictMode>
          <StoreProvider>
              <ThemeProvider>
                  <AppContainer id='app-container' className='container-lg p-0'>
                      <AppRouter />
                  </AppContainer>
              </ThemeProvider>
          </StoreProvider>
      </StrictMode>
  )
}
