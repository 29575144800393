import {VFC, useEffect} from "react"
import {InputField} from "../../../../form/controls/input-field"
import {actionCreators as cardActionCreators} from "../../../../../../store/slices/card"
import {useDispatch} from "react-redux"
import {InputFieldProps} from "../../../../../../types/form/controls"
import {CardNumberLabel} from "./label"
import {cardTypeByNumber} from "../../../../../../services/card/type-by-number"
import {useCardNumberMask} from '../../hooks/use-card-number-mask'
import {narrowToString} from "../../helpers/narrow-to-string"

export const CardNumber: VFC<InputFieldProps> = (props) => {
    const dispatch = useDispatch()
    const cardNumberMaskProps = useCardNumberMask()

    useEffect(() => {
        dispatch(
            cardActionCreators.setType(
                cardTypeByNumber(
                    narrowToString(props.value)
                )
            )
        )
    }, [props.value])

    return (
        <InputField
            label={<CardNumberLabel />}
            name="card.number"
            autoComplete="card.number"
            {...props}
            {...cardNumberMaskProps}
        />
    )
}
