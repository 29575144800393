import {
    DeliveringInput, DeliveringResult,
    PaymentMethodIdAware, PersistAddressInput,
    ProcessingInput,
    ProcessingResult, SavePrimaryFlagInput,
    SessionData,
    UpdatePaymentProviderInput
} from "./types"
import {config} from "../../config/config"
import {createClient} from "../helpers/create-client"

const {fetchData} = createClient(config.api.lira)

export const fetchSession = (): Promise<SessionData> => {
    return fetchData<void, SessionData>({
        method: 'get',
        url: 'api/payment-methods/external/session',
    })
}

export const fetchPaymentMethodId = async (): Promise<PaymentMethodIdAware> => {
    const {paymentMethodId} = await fetchSession()

    return { paymentMethodId }
}

export const persistAddress = (data: PersistAddressInput): Promise<SessionData> => {
    return fetchData<PersistAddressInput, SessionData>({
        method: 'post',
        url: 'api/payment-methods/external/session/address',
        data,
    })
}

export const savePrimaryCardFlag = (data: SavePrimaryFlagInput): Promise<SessionData> => {
    return fetchData<SavePrimaryFlagInput, SessionData>({
        method: 'post',
        url: 'api/payment-methods/external/session/primary',
        data,
    })
}

export const updatePaymentProvider = (data: UpdatePaymentProviderInput): Promise<SessionData> => {
    return fetchData<UpdatePaymentProviderInput, SessionData>({
        method: 'post',
        url: 'api/payment-methods/external/session/payment-provider',
        data,
    })
}

export const startProcessing = (data: ProcessingInput): Promise<ProcessingResult> => {
    return fetchData<ProcessingInput, ProcessingResult>({
        method: 'post',
        url: 'api/payment-methods/external/session/process',
        data,
    })
}

export const startDelivering = (data: DeliveringInput): Promise<DeliveringResult> => {
    return fetchData<DeliveringInput, DeliveringResult>({
        method: 'post',
        url: 'api/payment-methods/external/session/deliver',
        data,
    })
}
