import i18n from "i18next"
import en from "./resources/en.json"
import { initReactI18next } from "react-i18next"

const resources = {
    en
}

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: 'en',
        interpolation: {
            escapeValue: false
        }
    })

export default i18n
