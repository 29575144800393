import {useEffect, useState} from "react"
import { Observable, EMPTY } from "rxjs"
import {PollingObservableConfig} from "../types/types"
import {createPollingObservable} from "../observables/create-polling"

export const usePollingObservable = <T>(
    { pollFn, testFn, settings}: PollingObservableConfig<T>,
    initWhen: boolean
): Observable<T> => {
    const [observable$, setObservable] = useState<Observable<T>>(EMPTY)

    useEffect(() => {
        if(!initWhen) {
            setObservable(EMPTY)
            return
        }

        setObservable(createPollingObservable<T>({
            pollFn,
            testFn,
            settings
        }))
    }, [pollFn, testFn, settings, initWhen])

    return observable$
}
