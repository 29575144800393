import {Banner} from "./banner"
import {Row} from "@malwarebytes/react-components/build/row"
import {Col} from "@malwarebytes/react-components"
import {ColRight} from "./col-right"
import {FC} from "react"
import {CloseIcon} from "./close/close-icon"
import styled from "styled-components"

const BannerText = styled(Col)`
  height: 24px;
  font-size: 1rem;
  line-height: 1.5;
`

type HeaderProps = {
    text: string
}
export const Header: FC<HeaderProps> = (props) => {
    return (
        <Banner type="primary">
            <Row>
                <BannerText>
                    {props.text}
                </BannerText>
                <ColRight/>
            </Row>
        </Banner>
    )
}
