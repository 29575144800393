import {ScriptStatus, useExternalScript} from "../../../../../../../../hooks/dom/use-external-script"
import {
    AcceptJsConfig,
    DispatchDataResponse,
    PaymentDataWithCard,
    SecureData
} from "../../../../../../../../types/provider/accept-js"
import {useEffect, useState} from "react"
import {config} from "../../../../../../../../config/config"
import {PaymentProvider} from "../../../../../../../../types/payment-provider"

type AcceptJsResult = {
    loading: boolean,
    dispatchData: (input: PaymentDataWithCard) => Promise<DispatchDataResponse>
}

export const useAcceptJs = (
    {scriptUrl, clientKey, apiLoginID}: AcceptJsConfig
): AcceptJsResult => {
    const [loading, setLoading] = useState(true)

    const scriptStatus = useExternalScript(scriptUrl)

    useEffect(() => {
        if(scriptStatus === ScriptStatus.READY) {
            setLoading(false)
        }
    }, [scriptStatus])

    const dispatchData = (input: PaymentDataWithCard): Promise<DispatchDataResponse> => {
        const payload: SecureData = {
            cardData: input.cardData,
            authData: {
                clientKey,
                apiLoginID,
            }
        }

        return new Promise<DispatchDataResponse>(((resolve, reject) => {
            if(window && window.Accept) {
                window.Accept.dispatchData(
                    payload,
                    (response) => {
                        if (response.messages.resultCode === 'Ok') {
                            resolve(response)
                        }else{
                            reject(response)
                        }
                    }
                )
            }
        }))
    }

    return {
        loading,
        dispatchData
    }
}

export const useAcceptJsWithConfig = (): AcceptJsResult => useAcceptJs(
    config.payment.providers[PaymentProvider.AUTH_NET].settings
)
