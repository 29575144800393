import {VFC} from "react"
import {useFormikContext} from 'formik'
import {Row} from "@malwarebytes/react-components/build/row"
import {CustomerForm} from "../../../../../types/forms"
import {CardNumber} from "./number/number"
import {VerificationNumber} from "./verification-number/verification-number"
import {Col} from "@malwarebytes/react-components"
import {Expiration} from "./expiration/expiration"
import {InputField} from "../../../form/controls/input-field"
import {useTranslation} from "react-i18next"

export const CardData: VFC = () => {
    const { t } = useTranslation()

    const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange
    } = useFormikContext<CustomerForm>()

    return (
        <div className='px-3'>
            <Row className='pb-3'>
                <CardNumber
                    value={values.card.number}
                    error={touched.card?.number ? errors.card?.number : null}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    />
            </Row>
            <Row className='pb-3'>
                <Col xs={12} sm={6} className='pb-3 pb-sm-0'>
                    <Expiration
                        value={values.card.expiration}
                        error={touched.card?.expiration ? errors.card?.expiration : null}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        />
                </Col>

                <Col>
                    <VerificationNumber
                        value={values.card.verificationNumber}
                        error={touched.card?.verificationNumber ? errors.card?.verificationNumber : null}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Col>
            </Row>
            <Row className='pb-3'>
                <Col xs={12} sm={6} className='pb-3 pb-sm-0'>
                    <InputField
                        label={t('form.address.firstName')}
                        name="address.firstName"
                        autoComplete="address.firstName"
                        value={values.address.firstName}
                        error={touched.address?.firstName ? errors.address?.firstName : null}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Col>
                <Col>
                    <InputField
                        label={t('form.address.lastName')}
                        name="address.lastName"
                        autoComplete="address.lastName"
                        value={values.address.lastName}
                        error={touched.address?.lastName ? errors.address?.lastName : null}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Col>
            </Row>
        </div>
    )
}
