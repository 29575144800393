import {GASubscriber} from './google-analytics'

const marketingSystems = [
    GASubscriber
]

export const initMarketingSubscribers = () => {
    for (const subscriber of marketingSystems) {
        subscriber()
    }
}