import {useAppSelector} from "../../../../../store/hooks/use-app-selector"
import {getPaymentProvider} from "../../../../../store/slices/card/selectors"
import {CardData} from "../../card-data/fields"
import {PaymentProvider} from "../../../../../types/payment-provider"
import {ChaseConnector} from "../chase-connector/chase-connector"

export const CardSwitch = () => {
    const paymentProvider = useAppSelector(state => getPaymentProvider(state.card))

    if (paymentProvider === PaymentProvider.AUTH_NET) {
        return <CardData/>
    }

    return <ChaseConnector/>
}