import {InputField} from "../../../../form/controls/input-field"
import {VFC} from "react"
import {InputFieldProps} from "../../../../../../types/form/controls"
import {VerificationNumberLabel} from "./label"
import {useVerificationNumberMask} from "../../hooks/use-verification-number-mask"

export const VerificationNumber: VFC<InputFieldProps> = (props) => {
    const verificationNumberMaskProps = useVerificationNumberMask()

    return (
        <InputField
            label={<VerificationNumberLabel />}
            name="card.verificationNumber"
            placeholder='CVV'
            {...props}
            {...verificationNumberMaskProps}
        />
    )
}
