import {ComponentType} from "react"
import {useSessionStateSelector} from "../../../../store/slices/session/hooks"
import {getPaymentMethodId} from "../../../../store/slices/session/selectors"
import {useSessionLoaded} from "../../../../hooks/session/use-session-loaded"
import {startDelivering} from "../../../../api/lira/lira"
import {useProceed} from "./hooks/use-proceed"

export const withDeliveringPerforming = <P,>(Component: ComponentType<P>): ComponentType<P> => {
    return (props: P) => {
        const proceed = useProceed()

        const paymentMethodId = useSessionStateSelector(state => getPaymentMethodId(state))

        useSessionLoaded(() => {
            if(!paymentMethodId){
                return
            }

            startDelivering({paymentMethodId})
                .then(({success}) => proceed(success))
                .catch(() => proceed(false))

        }, [paymentMethodId])

        return <Component {...props} />
    }
}
