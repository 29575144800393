import {getAddressCustomerForm, getMakePrimaryCard} from "../../../../../store/slices/session/selectors"
import {CustomerForm, CustomerFormAddress, CustomerFormExtra} from "../../../../../types/forms"
import {useState} from "react"
import {useSessionStateSelector} from "../../../../../store/slices/session/hooks"
import {useSessionLoaded} from "../../../../../hooks/session/use-session-loaded"

export const useInitialValues = (): CustomerForm => {
    const [address, setAddress] = useState<CustomerFormAddress>({
        firstName: '',
        lastName: '',
        country: '',
        stateProvince: '',
        postalCode: '',
    })

    const [extra, setExtra] = useState<CustomerFormExtra>({makePrimary: false})

    const [stateAddress, makePrimary] = useSessionStateSelector<[CustomerFormAddress, boolean]>(state => ([
        getAddressCustomerForm(state),
        getMakePrimaryCard(state)
    ]))

    useSessionLoaded(() => {
        setAddress(stateAddress)
        setExtra({makePrimary})
    })

    return {
        card: {
            number: '',
            expiration: '',
            verificationNumber: ''
        },
        address,
        extra,
    }
}
