import {ComponentType, FC} from "react"
import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom"
import {SessionAware} from "../session/session-aware/session-aware"
import {Customer} from "../session/customer/customer"
import {Confirmation} from "../session/confirmation/confirmation"
import {SomethingWentWrong} from "../errors/something-went-wrong"
import {NotFound as PageNotFound} from "../errors/not-found"
import {Delivering} from "../session/delivering/delivering"
import {AuthNetProcessing} from "../session/processing/auth-net/processing"
import {ChaseProcessing} from "../session/processing/chase/processing"
import {routes} from "../../../config/routes/routes.config"
import {withPageTracking} from "./with-page-tracking"
import {ProcessingChaseNeo} from "../session/processing/chase-neo/processing-chase-neo"

export const AppRouter: FC = () => {

    const AppRoutes: ComponentType = withPageTracking(() => {
        return (
            <Routes>
                <Route
                    path={routes.session.getPath()}
                    element={<SessionAware />}
                >
                    <Route
                        path={routes.session.customer.getPath()}
                        element={<Customer/>}
                    />
                    <Route
                        path={routes.session.processing.getPath()}
                    >
                        <Route
                            path={routes.session.processing.authNet.getPath()}
                            element={<AuthNetProcessing/>}
                        />
                        <Route
                            path={routes.session.processing.chase.getPath()}
                            element={<ChaseProcessing/>}
                        />
                    </Route>
                    <Route
                        path='/session/:id/:pwd/process/chase'
                        element={<ProcessingChaseNeo/>}
                    />
                    <Route
                        path={routes.session.delivering.getPath()}
                        element={<Delivering/>}
                    />
                    <Route
                        path={routes.session.confirmation.getPath()}
                        element={<Confirmation/>}
                    />
                    <Route
                        path={routes.session.failed.getPath()}
                        element={<SomethingWentWrong/>}
                    />
                    <Route
                        path="*"
                        element={<Navigate to="customer" />}
                    />
                </Route>
                <Route
                    path="*"
                    element={<PageNotFound />}
                />
            </Routes>
        )
    })

    return (
        <BrowserRouter>
            <AppRoutes/>
        </BrowserRouter>
    )
}
