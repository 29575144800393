import {CreditCardType} from "../../../../types/credit-card"
import {ValidationFn} from "../../types"
import {toYupTest} from "../../helpers/to-yup-test"
import i18n from "../../../../i18n"
import {verificationNumberLength} from "../../../card/verification-number-length"
import {TestConfig} from "yup"

export const creditCardVerificationNumber = (type: Nullable<CreditCardType>): ValidationFn<string> => (value) => {
    const size = verificationNumberLength(type)

    if(value?.length === size) {
        return true
    }

    return i18n.t<string>('validation.card.verificationNumber.length', {size})
}

export const asYupTest = (type: Nullable<CreditCardType>): TestConfig<string | undefined> => toYupTest({
    name: 'creditCardVerificationNumber',
    validationFn: creditCardVerificationNumber(type)
})
