import styled from "styled-components"

type BannerProps = {
    type: 'primary' | 'secondary'
}

export const Banner = styled.div<BannerProps>`
  ${p => p.type === 'primary' 
          ? ({
            'padding': '16px 22px',
            'background-color': p.theme.banner.backgroundPrimary,
            'color': p.theme.banner.text
            })
          : ({
            'padding': '22px 40px',
            'background-color': p.theme.banner.backgroundSecondary,
            'color': 'none'
          })
}
`
