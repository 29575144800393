/**
 * Extract boolean value from string 'true'/'false' passed in env-variable
 */
export const booleanFromEnv = (value: string | undefined, defaultValue = false): boolean => {
    if (value === 'true') {
        return true
    }

    if (value === 'false') {
        return false
    }

    return defaultValue
}

export const trimRightSlashFromUrl = (url: string | undefined): string => {
    if(!url) {
        return ''
    }

    url = url.replace(/\/+$/, '')

    return url
}

export const trueIfNotEmpty = (value: unknown): boolean => {
    return !!value
}

export const numberFromEnv = (value: string): number => {
    const result = parseFloat(value)
    if(isNaN(result)){
        return 0
    }

    return result
}
