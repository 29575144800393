import {useEffect, useState} from "react"
import {appendScriptElements, performRemove} from "../../../../../../services/utility/script-elements"
import {composeWpwlOptions} from "../compose-options"
import {useRequestCheckoutId} from "./use-request-checkout-id"
import {config} from "../../../../../../config/config"

export const useInitiateCheckout = () => {
    const [isInitiated, setIsInitiated] = useState(false)

    const checkoutId = useRequestCheckoutId()

    useEffect(() => {
        if (!checkoutId) {
            return
        }

        const scripts = appendScriptElements([
            {
                text: `var wpwlOptions = ${composeWpwlOptions.name}();` + composeWpwlOptions.toString(),
            },
            {
                src: `${config.chase.scriptUrl}?checkoutId=${checkoutId}`,
            },
        ])

        setIsInitiated(true)

        return () => {
            if (window.wpwl !== undefined && window.wpwl.unload !== undefined) {
                window.wpwl.unload()
            }

            performRemove(scripts)
        }
    }, [
        setIsInitiated,
        checkoutId
    ])

    return isInitiated
}