import {SessionState, sliceName} from "./types"
import {extraReducers, thunks} from "./thunks"
import {reducers} from "./reducers"
import {createSlice} from "@reduxjs/toolkit"
import {LoadingState} from "../../../types/store"
import {Flow} from "../../../types/flow"

const initialState: SessionState = {
    loadingStatus: LoadingState.PENDING,
    params: {
        id: '',
        pwd: ''
    },
    authToken: '',
    data: {
        firstName: '',
        lastName: '',
        country: '',
        stateProvince: '',
        postalCode: '',
        paymentMethodId: '',
        makePrimaryCard: false
    },
    flow: Flow.ADD_CARD,
    localError: ''
}

const {
    reducer,
    actions: actionCreators,
} = createSlice({
    name: sliceName,
    initialState,
    reducers,
    extraReducers,
})

export {
    reducer,
    actionCreators,
    thunks,
}

export * from './types'
