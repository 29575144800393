import styled from "styled-components"
import {ThemeType} from '../../../../types/theme'

type WrapProps = {
    transparent: boolean,
}

export const LoaderWrap = styled.div<WrapProps>`
  position: absolute;
  background: ${p => p.theme.mode === ThemeType.DARK ? '#2d2d2d' : '#FFF'};
  opacity: ${p => p.transparent ? 0.8 : 1 };
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export const LoaderRelative = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

export const LoaderBlock = styled.div`
  position: absolute;
  text-align: center;
  width: 100%;
  top: 50%;
  transform: translate(0, -50%);
`
