import {CreditCardType} from "../../../../types/credit-card"
import {ValidationFn} from "../../types"
import {toYupTest} from "../../helpers/to-yup-test"
import i18n from "../../../../i18n"
import {cardTypeByNumber} from "../../../card/type-by-number"
import {TestConfig} from "yup"
import {cardNameByType} from "../../../card/name-by-type"

export const creditCardSupported = (supportedTypes: CreditCardType[] = []): ValidationFn<string> => (value) => {
    const type = cardTypeByNumber(value)

    if(type && supportedTypes.length > 0 && !Object.values(supportedTypes).includes(type)) {
        return i18n.t<string>(
            'validation.card.number.supported',
            { type: cardNameByType(type) }
        )
    }

    return true
}

export const asYupTest = (supportedTypes: CreditCardType[] = []): TestConfig<string | undefined> => toYupTest({
    name: 'creditCardNumber',
    validationFn: creditCardSupported(supportedTypes)
})
