export const parseUrlQuery = (queryString: string): Record<string, string> => {
    const searchParams = new URLSearchParams(queryString)

    return Array
        .from(searchParams)
        .reduce((result ,[key, val]) => ({
            ...result,
            [key]: val
        }), {})
}
