import styled from "styled-components"
import {ThemeType} from "../../../../types/theme"

type LogoProps = {
    height: number,
    active: boolean
}

export const Logo = styled.img<LogoProps>`
    transition: 0.3s;
    margin-left: 10px;
    filter: grayscale(${p => !p.active 
            ? (p.theme.mode === ThemeType.DARK ? 0.65 : 100) 
            : 0});
    opacity: ${p => !p.active ? 0.6 : 1};
    height: ${p => p.height}px;
`
