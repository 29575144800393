import {useEffect, useState} from "react"
import {parseUrlQuery} from "../services/utility/helpers/parse-url-query"

/**
 * This will allow us to grab query parameters without having to be in a route's scope
 * Ex: grabbing the theme before routes have been rendered
 */
export const useQueryParameters = (): Record<string, string> => {
    const [params, setParams] = useState<Record<string, string>>({})

    useEffect(() => {
        setParams(parseUrlQuery(window.location.search))
    }, [])

    return params
}
