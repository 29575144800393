import {useTranslation} from "react-i18next"
import {CardLogos} from "./logos/logos"
import {Row} from "@malwarebytes/react-components/build/row"
import {Col} from "@malwarebytes/react-components"
import {ColRight} from "../../../../general/blocks/col-right"
import {VFC} from "react"

export const CardNumberLabel: VFC = () => {
    const { t } = useTranslation()

    return (
        <Row>
            <Col>
                {t('form.card.number')}
            </Col>

            <ColRight>
                <CardLogos />
            </ColRight>
        </Row>
    )
}
