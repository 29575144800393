import {TestConfig} from "yup"
import {ValidationFn} from "../types"

type ToYupTestArgs<T> = {
    name: string,
    message?: string,
    validationFn: ValidationFn<T>
}

export const toYupTest = <T>({name, message, validationFn}: ToYupTestArgs<T>): TestConfig<T | undefined> => {
    return {
        name,
        message,
        test(value: T | undefined) {
            const result = validationFn(value)

            if (typeof result === 'boolean') {
                return result
            }

            return this.createError({
                message: result
            })
        }
    }
}
