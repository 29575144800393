import {Loader} from "../../../components/general/loader/loader"
import {useTranslation} from "react-i18next"
import {ComponentType} from "react"
import {withDeliveringPerforming} from "./with-delivering-performing"

export const Delivering: ComponentType = withDeliveringPerforming(() => {
    const { t } = useTranslation()

    return (
        <Loader
            transparent={false}
            header={t('loader.header.processing')}
            text={t('loader.text.processing')}
        />
    )
})
