import {VFC} from "react"
import {Spinner} from "@malwarebytes/react-components"
import {LoaderBlock, LoaderRelative, LoaderWrap} from "./pieces"

type LoaderProps = {
    transparent: boolean,
    header?: string,
    text?: string
}

export const Loader: VFC<LoaderProps> = (props) => {
    return (
        <LoaderWrap transparent={props.transparent}>
            <LoaderRelative>
                <LoaderBlock>
                    {props.header && (
                        <h4 className="pt-3 pb-3" data-testid="header">
                            {props.header}
                        </h4>
                    )}

                    {props.text && (
                        //TODO: move inline style to class after deadline
                        <div data-testid="text"
                             className="mb-4"
                             style={{maxWidth: '242px', margin: 'auto', lineHeight: '1rem'}}>
                            {props.text}
                        </div>
                    )}

                    <div className="mb-3" data-testid="spinner">
                        <Spinner />
                    </div>
                </LoaderBlock>
            </LoaderRelative>
        </LoaderWrap>
    )
}
