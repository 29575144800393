import {CardState} from "./types"
import {PayloadAction} from "@reduxjs/toolkit"
import {CustomerFormCard} from "../../../types/forms"
import {PaymentProvider} from "../../../types/payment-provider"
import {CreditCardType} from "../../../types/credit-card"

const setCardAddressForm = (state: CardState, action: PayloadAction<CustomerFormCard>): void => {
    state.data.number = action.payload.number
    state.data.expirationDate = action.payload.expiration
    state.data.verificationNumber = action.payload.verificationNumber
}

const setNumber = (state: CardState, action: PayloadAction<string>): void => {
    state.data.number = action.payload
}

const setExpiration = (state: CardState, action: PayloadAction<string>): void => {
    state.data.expirationDate = action.payload
}

const setVerificationNumber = (state: CardState, action: PayloadAction<string>): void => {
    state.data.verificationNumber = action.payload
}

const setPaymentProvider = (state: CardState, action: PayloadAction<Nullable<PaymentProvider>>): void => {
    state.paymentProvider = action.payload
}

const setType = (state: CardState, action: PayloadAction<Nullable<CreditCardType>>): void => {
    state.type = action.payload
}

const setChaseToken = (state: CardState, action: PayloadAction<string>): void => {
    state.chaseToken = action.payload
}

export const reducers = {
    setNumber,
    setExpiration,
    setVerificationNumber,
    setCardAddressForm,
    setPaymentProvider,
    setType,
    setChaseToken,
}
