import {useProcessingPoller} from "../../hooks/use-processing-poller"
import {config} from "../../../../../../config/config"
import {combineLatest, EMPTY, identity, map, Observable, timeout} from "rxjs"
import {makeTimoutConfig} from "../../../../../../services/realtime/hooks/server-provided/helpers/make-timeout-config"
import {useEffect, useState} from "react"
import {useAuthCollector} from "./use-auth-collector"
import {useCompleteCollector} from "./use-complete-collector"
import {PaymentMethodAware} from "../types/types"

export const useProcessingReceiver = (collectorId: Empty<string>): Observable<PaymentMethodAware> => {
    const [receiver$, setReceiver] = useState<Observable<PaymentMethodAware>>(EMPTY)

    const pollingO$ = useProcessingPoller()

    const auth$ = useAuthCollector(collectorId)

    const complete$ = useCompleteCollector(collectorId)

    useEffect(() => {
        const timeoutConfig = makeTimoutConfig({
            settings: config.timeout.processing.authNet.serverProvided,
            fallbackTo: pollingO$
        })

        const r$ = combineLatest([auth$, complete$])
            .pipe(
                map(([,completeEvent]) => {
                    const {paymentMethodId} = completeEvent.message

                    if(!paymentMethodId) {
                        throw new Error('Payment method id is empty')
                    }

                    return {paymentMethodId}
                }),
                timeoutConfig ? timeout(timeoutConfig) : identity,
            )

        setReceiver(r$)
    }, [pollingO$, auth$, complete$])

    return receiver$
}
