import {VFC} from "react"
import {getCardType, getSupportedCardTypes} from "../../../../../../../store/slices/card/selectors"
import {CardLogos as BaseLogos} from "../../../../../general/card-logos/card-logos"
import {notNullValues} from "../../../../../../../services/utility/helpers/array/not-null-values"
import {useCardStateSelector} from "../../../../../../../store/slices/card/hooks"

export const CardLogos: VFC = () => {

    const {types, selected} = useCardStateSelector(state => ({
        types: getSupportedCardTypes(state),
        selected: [getCardType(state)].filter(notNullValues)
    }))

    return (
        <BaseLogos
            display={types}
            inColor={selected}
            height={15}
        />
    )
}
