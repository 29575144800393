import {LightStyle} from "./styles/light/light"
import {DarkStyle} from "./styles/dark/dark"
import {ThemeType} from "../../types/theme"
import {BaseStyle} from "./styles/base-style"

const styleMap: Record<ThemeType, BaseStyle> = {
    [ThemeType.LIGHT]: LightStyle,
    [ThemeType.DARK]: DarkStyle,
}

export const themeToStyle = (theme: ThemeType): BaseStyle => {
    return styleMap[theme] ?? LightStyle
}
