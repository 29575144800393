import {
    useWebsocketsObservable
} from "../../../../../../services/realtime/hooks/server-provided/use-websockets-observable"
import {ProcessingCompleteEvent} from "../types/types"
import {sameInputTransformer} from "../../../../../../services/utility/same-input-transformer"
import {Observable} from "rxjs"

export const useCompleteCollector = (collectorId: Empty<string>): Observable<ProcessingCompleteEvent> => {
    return useWebsocketsObservable<ProcessingCompleteEvent>({
        channelName: collectorId,
        eventName: 'complete',
        transformer: sameInputTransformer
    })
}
