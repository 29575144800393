import {useEffect, useState} from "react"
import {useParams} from "react-router-dom"
import {useSseObservable} from "../../../../../../services/realtime/hooks/server-provided/use-sse-observable"
import {processingEventSourceUrl} from "../../../../../../api/jupiter/jupiter-sse"
import {sameInputTransformer} from "../../../../../../services/utility/same-input-transformer"
import {config} from "../../../../../../config/config"
import {generateSecurityToken} from "../../../../../../api/jupiter/jupiter"
import {AuthorizationResult} from "../types/types"
import {closeSubscription} from "../../../../../../services/realtime/helpers/close-subscription"
import {useProceed} from "../../hooks/use-proceed"
import {useSessionLoaded} from "../../../../../../hooks/session/use-session-loaded"

const {serverProvided} = config.timeout.processing.chase

export const useReceiveAuthorizationStatus = (): Optional<AuthorizationResult> => {
    const [result, setResult] = useState<AuthorizationResult>()
    const [securityToken, setSecurityToken] = useState('')

    const {error: proceedWithError} = useProceed()

    const {collectorId} = useParams()

    useSessionLoaded(() => {
        generateSecurityToken()
            .then(({token}) => setSecurityToken(token))
            .catch(err => proceedWithError(err))
    })

    const receiver$ = useSseObservable<AuthorizationResult>({
        channelName: processingEventSourceUrl(collectorId, securityToken),
        eventName: 'authorization_status',
        settings: serverProvided,
        transformer: sameInputTransformer,
    })

    useEffect(() => {
        const subscription = receiver$.subscribe({
            next: (r) => {
                console.log('Authorization result received', {r})
                setResult(r)
                closeSubscription(subscription)
            },
            error: err => {
                console.error('Error receiving authorization status', err)
                proceedWithError(err)
            }
        })

        return () => closeSubscription(subscription)
    }, [receiver$])

    return result
}
