import {
    useWebsocketsObservable
} from "../../../../../../services/realtime/hooks/server-provided/use-websockets-observable"
import {ProcessingAuthEvent} from "../types/types"
import {ErrorWithMessage} from "../../../../../../errors/error-with-message"
import {Observable} from "rxjs"
import {getErrorMessageWithConfig} from "../../helpers/get-error-message"
import {PaymentProvider} from "../../../../../../types/payment-provider"

export const useAuthCollector = (collectorId: Empty<string>): Observable<ProcessingAuthEvent> => {
    return useWebsocketsObservable<Stringified<ProcessingAuthEvent>, ProcessingAuthEvent>({
        channelName: collectorId,
        eventName: 'auth',
        transformer: (string) => {
            const data = JSON.parse<ProcessingAuthEvent>(string)

            if (!data.success) {
                throw new ErrorWithMessage(
                    getErrorMessageWithConfig(PaymentProvider.AUTH_NET, data.result.code)
                )
            }

            return data
        }
    })
}
