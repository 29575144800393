import {VFC} from "react"
import {FormControl, FormGroup, FormLabel} from "@malwarebytes/react-components/build/form"
import {InputFieldProps} from "../../../../types/form/controls"
import {ErrorBlock} from "../error-block"

export const InputField: VFC<InputFieldProps> = (props) => {
    return (
        <FormGroup className=''>
            {props.label && (
                <FormLabel className='w-100'>
                    {props.label}
                </FormLabel>
            )}
            <FormControl {...props} beforeMaskedStateChange={props.beforeMaskedStateChange} />
            {props.error && <ErrorBlock error={props.error} />}
        </FormGroup>
    )
}
