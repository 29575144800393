import {PaymentProvider} from "../../types/payment-provider"

export type ProvidersMapping = {
    default: PaymentProvider,
    countries: {
        [key: string]: PaymentProvider
    }
}

export const providersMapping: ProvidersMapping = {
    default: PaymentProvider.CHASE_DHPP,
    countries: {
        ['US']: PaymentProvider.AUTH_NET,
        ['CA']: PaymentProvider.AUTH_NET,
    }
}
