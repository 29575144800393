import {CardState} from "./types"
import {getCardState} from "./selectors"
import {useAppSelector} from "../../hooks/use-app-selector"
import {SliceSelector} from "../../../types/store"

export const useCardStateSelector = <TSelected = unknown>(
    selector: SliceSelector<CardState, TSelected>
): TSelected => {
    return useAppSelector((state) => selector(getCardState(state)))
}
