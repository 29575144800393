import ReactGA from 'react-ga'
import {config} from '../../../../config/config'
import {pageViews, events} from '../../index'

export const GASubscriber = () => {
    ReactGA.initialize(config.marketing.ga.id)

    pageViews
        .subscribe((event) => {
            ReactGA.pageview(event.url)
        })

    events
        .subscribe((event) => {
            ReactGA.event({
                category: 'OnePay B2B BSM',
                action: event.action,
                label: JSON.stringify(event.data),
            })
        })
}