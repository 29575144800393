import {ComponentType} from "react"
import {useReceiveAuthorizationStatus} from "./hooks/use-receive-authorization-status"
import {useUnsuccessfulAuthorization} from "./hooks/use-unsuccessful-authorization"
import {PropsWithAuthorizationResult} from "./types/types"

export const withAuthorizationStatusReceiving = <P,>(
    Component: ComponentType<PropsWithAuthorizationResult<P>>
): ComponentType<P> => {
    return (props: P) => {

        const authorizationResult = useReceiveAuthorizationStatus()

        useUnsuccessfulAuthorization(authorizationResult)

        return <Component {...props} authorizationResult={authorizationResult} />
    }
}
