export function composeWpwlOptions() {
    return {
        style: 'plain',
        locale: 'en',
        maskCvv: true,
        requireCvv: true,
        allowEmptyCvv: false,
        disableCardExpiryDateValidation: false,
        enableSAQACompliance: true,
        disableSubmitOnEnter: false,
        brandDetectionType: 'binlist',
        brandDetectionPriority: [
            'CARTEBANCAIRE',
            'VISA',
            'MAESTRO',
            'MASTER',
        ],
        brandDetection: true,
        paymentTarget: '_self',
        spinner: {
            color: '#0d3ecc',
            zIndex: 200,
        },
        onReady: function () {
            const cvvGroup = document.querySelector('.wpwl-group-cvv')
            const cardHolderGroup = document.querySelector('.wpwl-group-cardHolder')
            if (cardHolderGroup && cvvGroup) {
                cardHolderGroup.before(cvvGroup)
            }

            const ccNumber = document.querySelector('.wpwl-label-cardNumber')
            if (ccNumber) {
                ccNumber.innerHTML = 'Credit card number'
            }

            const fullName = document.querySelector('.wpwl-label-cardHolder')
            if (fullName) {
                fullName.innerHTML = 'Full name'
            }

            const securityCode = document.querySelector('.wpwl-label-cvv')
            if (securityCode) {
                securityCode.innerHTML = 'Security code'
            }

            const expiration = document.querySelector('.wpwl-label-expiry')
            if (expiration) {
                expiration.innerHTML = 'Card expiration'
            }
        },
        onDetectBrand: function (brands: unknown, activeBrand: string) {
            window.chaseForm.setCardBrand(activeBrand)
        },
        onBlurSecurityCode: function (isValid: boolean) {
            window.chaseForm.setSecurityCode(isValid)
        },
        onBlurCardNumber: function (isValid: boolean) {
            window.chaseForm.setCardNumber(isValid)
        },
        onBlurCardHolder: function (isValid: boolean) {
            window.chaseForm.setCardHolder(isValid)
        },
        onBlurExpiryDate: function (isValid: boolean) {
            window.chaseForm.setCardExpiration(isValid)
        },
    }
}