import {PaymentProvider} from "../../../types/payment-provider"
import {CreditCardType} from "../../../types/credit-card"

export const sliceName = 'card'

export type CardState = {
    data: {
        number: string,
        expirationDate: string,
        verificationNumber: string
    },
    type: Nullable<CreditCardType>,
    paymentProvider: Nullable<PaymentProvider>,
    chaseToken: string,
}
