import cardValidator from "card-validator"
import {ValidationFn} from "../../types"
import {toYupTest} from "../../helpers/to-yup-test"
import i18n from "../../../../i18n"
import {TestConfig} from "yup"
import {config} from "../../../../config/config"

export const creditCardExpiration = (): ValidationFn<string> => (value) => {
    const expiration = cardValidator.expirationDate(value, config.payment.maxElapsedYear)

    return expiration.isValid
}

export const asYupTest = (): TestConfig<string | undefined> => toYupTest({
    name: 'creditCardExpiration',
    message: i18n.t('validation.card.expiration.valid'),
    validationFn: creditCardExpiration()
})
