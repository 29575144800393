import {UseProceedResult} from "../auth-net/types/types"
import {useSessionNavigate} from "../../../../../hooks/session/use-session-navigate"
import {useDispatch} from "react-redux"
import {actionCreators} from "../../../../../store/slices/session"
import {routes} from "../../../../../config/routes/routes.config"
import {composeErrorMessage} from "../../../../../errors/compose-error-message"

export const useProceed = (): UseProceedResult => {
    const navigate = useSessionNavigate()
    const dispatch = useDispatch()

    return {
        success: (paymentMethodId) => {
            dispatch(actionCreators.setPaymentMethodId(paymentMethodId))

            navigate(routes.session.delivering)
        },
        error: (err) => {
            dispatch(actionCreators.setLocalError(composeErrorMessage(err)))

            navigate(routes.session.customer)
        }
    }
}
