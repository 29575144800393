import {useAppDispatch} from '../../../../../store/hooks/use-app-dispatch'
import {useEffect} from "react"
import {actionCreators as cardActionCreators} from "../../../../../store/slices/card"
import {thunks} from "../../../../../store/slices/session"
import {
    determinePaymentProvider
} from "../../../../../services/utility/helpers/payment-provider/determine-payment-provider"

export const usePaymentProviderSwitch = (country: string | undefined): void => {
    const dispatch = useAppDispatch()

    useEffect(() => {
        const paymentProvider = determinePaymentProvider(country)

        dispatch(cardActionCreators.setPaymentProvider(paymentProvider))

        if (paymentProvider) {
            dispatch(thunks.updatePaymentProvider(paymentProvider))
                .unwrap()
                .then(({paymentProvider: updatedPaymentProvider}) => {
                    if (updatedPaymentProvider && paymentProvider !== updatedPaymentProvider) {
                        /**
                         * Lira will send back a different provider than our `determinePaymentProvider` function
                         * This is for the scenario where the original account has chase and they add a US card.
                         * Our fn will return auth.net but Lira will send back chase
                         */
                        dispatch(cardActionCreators.setPaymentProvider(updatedPaymentProvider))
                    }
                })
        }
    }, [country])
}
