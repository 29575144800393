import {ComponentType} from "react"
import {PropsWithAuthorizationResult} from "./types/types"
import {useCollectPaymentMethod} from "./hooks/use-collect-payment-method"

export const withPaymentMethodCollecting = <P,>(
    Component: ComponentType<P>
): ComponentType<PropsWithAuthorizationResult<P>> => {
    return (props: PropsWithAuthorizationResult<P>) => {

        useCollectPaymentMethod(props.authorizationResult)

        return <Component {...props} />
    }
}
