import {config} from "../../config/config"
import {IframeEvent} from "../../config/iframe/iframe-event"

const makeTargetOrigins = (): string[] => {
    if(!config.iframe.postMessage.enableOriginRestriction) {
        return ['*']
    }

    return config.iframe.postMessage.targetOrigins.filter(t => Boolean(t))
}

const targetOrigins = makeTargetOrigins()

export const sendToParent = (handle: IframeEvent, payload?: Record<string, string>): void => {
    for(const targetOrigin of targetOrigins) {
        window.parent.postMessage({
            handle,
            payload
        }, targetOrigin)
    }
}
