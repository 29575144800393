import {CreditCardType} from "../../../types/credit-card"

const map: Partial<Record<CreditCardType, string>> = {
    [CreditCardType.AMEX]: '9999 - 999999 - 99999'
}

const def = '9999 - 9999 - 9999 - 9999'

export const cardNumberMask = (type: Nullable<CreditCardType>): string => {
    if(type !== null) {
        return map[type] || def
    }

    return def
}
