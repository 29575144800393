import {Observable} from "rxjs"
import {UseServerProvidedObservableConfig} from "../../types/types"
import {useMemo} from "react"
import {pusherAdapter} from "./adapters/pusher-adapter"
import {useServerProvidedObservable} from "./use-server-provided-observable"

export const useWebsocketsObservable = <ST, RT = ST>(
    config: UseServerProvidedObservableConfig<ST, RT>
): Observable<RT> => {
    const client = useMemo(() => pusherAdapter<ST>(), [])

    return useServerProvidedObservable(client, config)
}
