import { FC } from 'react'
import { Provider } from 'react-redux'
import { store } from './store'

export const StoreProvider: FC = (props) => {
    return (
        <Provider store={store}>
            {props.children}
        </Provider>
    )
}
