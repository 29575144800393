import {useEffect, VFC} from 'react'
import styled from 'styled-components'
import {BorderlessCard} from "../../../components/general/blocks/borderless-card"
import {Row} from "@malwarebytes/react-components/build/row"
import {useTranslation} from "react-i18next"
import {Header} from "../../../components/general/blocks/header"
import {CatchPhrase} from "../../../components/errors/blocks/catch-phrase"
import {ErrorMessage} from "../../../components/errors/blocks/error-message"
import {Spacer} from "../../../components/errors/blocks/spacer"
import {useSessionStateSelector} from "../../../../store/slices/session/hooks"
import {getFlow} from "../../../../store/slices/session/selectors"
import {Flow} from "../../../../types/flow"
import {CloseBlock} from "../../../components/general/blocks/buttons/close-block"
import {IframeEvent} from '../../../../config/iframe/iframe-event'
import {sendToParent} from '../../../../services/iframe/send-to-parent'

const CardSuccessSvg = styled.img`
  content: url("${p => p.theme.svg.cardSuccess}");
  margin: auto;
  width: 232px;
  height: 173px;
`

export const Confirmation: VFC = () => {
    const {t} = useTranslation()

    const flow = useSessionStateSelector(state => getFlow(state))

    useEffect(() => {
        // Nebula will use this to resize their iframe
        sendToParent(IframeEvent.CHECKOUT_COMPLETED)
    }, [])

    const header = flow === Flow.REPLACE_CARD
        ? t('confirmation.headerReplaced')
        : t('confirmation.headerCreated')

    return (
        <div>
            <Header text={header}/>
            <BorderlessCard>
                <Row className="my-5">
                    <CardSuccessSvg />
                </Row>
                <CatchPhrase className="mb-3">
                    {t('confirmation.header')}
                </CatchPhrase>
                <ErrorMessage>
                    {t('confirmation.text')}
                </ErrorMessage>
                <Spacer height='107px' />
            </BorderlessCard>
            <CloseBlock event={IframeEvent.DISMISS_SUCCESSFUL_CART} />
        </div>
    )
}
