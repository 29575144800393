import {config} from "../../config/config"

const makeUrl = (endpoint: string, collectorId: Empty<string>, sseToken: Empty<string>): Nullable<string> => {
    if(!collectorId || !sseToken) {
        return null
    }

    return `${config.api.jupiter.url}/api/${endpoint}/${collectorId}/sse/status?authorization=${sseToken}`
}

export const doPrepareEventSourceUrl = (collectorId: Empty<string>, sseToken: Empty<string>): Nullable<string> => {
    return makeUrl('prepare', collectorId, sseToken)
}

export const processingEventSourceUrl = (collectorId: Empty<string>, sseToken: Empty<string>): Nullable<string> => {
    return makeUrl('authorization', collectorId, sseToken)
}
