import {VFC} from "react"
import {useTranslation} from "react-i18next"
import {Header} from "../general/blocks/header"
import {BorderlessCard} from "../general/blocks/borderless-card"
import {Row} from "@malwarebytes/react-components/build/row"
import {CatchPhrase} from "./blocks/catch-phrase"
import {ErrorMessage} from "./blocks/error-message"
import {Spacer} from "./blocks/spacer"
import styled from 'styled-components'
import {CloseBlock} from "../general/blocks/buttons/close-block"

const UnknownSvg = styled.img`
  content: url("${p => p.theme.svg.unknown}");
  margin: auto;
  width: 232px;
  height: 166.8px;
`

export const SessionNotFound: VFC = () => {
    const {t} = useTranslation()

    return (
        <div>
            <Header text={t('topBanner.textSessionNotFound')}/>
            <BorderlessCard>
                <Row className="my-5">
                    <UnknownSvg />
                </Row>
                <CatchPhrase className="mb-3">
                    {t('notFound.header')}
                </CatchPhrase>
                <ErrorMessage>
                    {t('notFound.text')}
                </ErrorMessage>
                <Spacer height='113px' />
            </BorderlessCard>
            <CloseBlock />
        </div>
    )
}
