import {ProceedFn} from "../types/types"
import {useSessionNavigate} from "../../../../../hooks/session/use-session-navigate"
import {routes} from "../../../../../config/routes/routes.config"

export const useProceed = (): ProceedFn => {
    const navigate = useSessionNavigate()

    return (success) => {
        navigate(success ? routes.session.confirmation : routes.session.failed)
    }
}
