import {createAsyncThunk} from "@reduxjs/toolkit"
import {PERSIST_ADDRESS_THUNK, SessionState} from "../types"
import {ThunkApiConfig} from "../../../store"
import {persistAddress} from "../../../../api/lira/lira"
import {
    getAddressCustomerForm,
    getSessionState,
} from "../selectors"
import {DefaultExtraReducersTree} from "../../../../types/store"
import {SessionData} from "../../../../api/lira/types"

const thunk = createAsyncThunk<SessionData, void, ThunkApiConfig>(
    PERSIST_ADDRESS_THUNK,
    (_, thunkAPI) => {
        const state = getSessionState(thunkAPI.getState())

        return persistAddress(getAddressCustomerForm(state))
    }
)

const reducers: DefaultExtraReducersTree<SessionState, SessionData> = {}

export {
    thunk,
    reducers
}
