import {useMemo} from "react"
import {config} from "../../../../../../config/config"
import {useAppSelector} from "../../../../../../store/hooks/use-app-selector"
import {getSessionParams} from "../../../../../../store/slices/session/selectors"

export const useFormAction = () => {
    const {id, pwd} = useAppSelector(state => getSessionParams(state.session))

    return useMemo(() => {
        if (!id || !pwd) {
            return ''
        }

        const parts = [
            config.app.selfUrl,
            'session',
            id,
            pwd,
            'process/chase'
        ]

        let url = parts.join('/')

        const passthrough = ''
        // const passthrough = new PassthroughComposer()
        //     .addParam('action', this.action)
        //     .addParam('theme', document.body.dataset.theme)
        //     .addParam('autoRenewal', this.amendmentAutoRenewal ? 'true' : 'false')
        //     .compose()

        if (passthrough) {
            url += `?${passthrough}`
        }

        return url
    }, [
        id, pwd
    ])
}