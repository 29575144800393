import {AuthorizationResult} from "../types/types"
import {useEffect} from "react"
import {useProcessingPoller} from "../../hooks/use-processing-poller"
import {useProceed} from "../../hooks/use-proceed"
import {closeSubscription} from "../../../../../../services/realtime/helpers/close-subscription"

export const useCollectPaymentMethod = (authorizationResult: Optional<AuthorizationResult>): void => {
    const poller$ = useProcessingPoller()

    const {success, error} = useProceed()

    useEffect(() => {
        if(!authorizationResult || !authorizationResult.success) {
            return
        }

        console.log('authorizationResult', authorizationResult)

        const subscription = poller$.subscribe({
            next: ({paymentMethodId}) => success(paymentMethodId),
            error: err => {
                console.error('Polling error', err)
                error(err)
            }
        })

        return () => closeSubscription(subscription)
    }, [poller$, authorizationResult, success, error])
}
