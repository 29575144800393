import {config} from "../../../../../../config/config"
import {pendingCodes, successCodes} from "../../../../../../services/utility/chase"
import {getCheckoutStatus} from "../../../../../../api/jupiter/jupiter"
import {useCallback, useEffect, useState} from "react"
import {asyncDelay} from "../../../../../../services/utility/async-delay"
import {useProceed} from "../../hooks/use-proceed"
import {useAppSelector} from "../../../../../../store/hooks/use-app-selector"
import {getAuthToken, getSessionParams, sessionIsLoaded} from "../../../../../../store/slices/session/selectors"

type Result = boolean

export const useCollectChaseStatus = (): Result => {
    const authToken = useAppSelector(state => getAuthToken(state.session))
    const isLoaded = useAppSelector(state => sessionIsLoaded(state.session))

    const [
        authorizationSuccess,
        setAuthorizationSuccess
    ] = useState(false)

    const {error: proceedWithError} = useProceed()

    const collectStatus = useCallback(async () => {
        const {
            maxAttempts,
            delayBetweenAttemptsInMs
        } = config.chase.collectingStatus

        let statusCode = ''
        let currentAttempt = 0

        do {
            const data = await getCheckoutStatus(authToken)

            statusCode = data.result.code
            currentAttempt++

            await asyncDelay(delayBetweenAttemptsInMs)
        } while (pendingCodes.includes(statusCode) || currentAttempt >= maxAttempts)

        return {
            success: successCodes.includes(statusCode),
            statusCode: statusCode,
        }
    }, [
        authToken
    ])

    useEffect(() => {
        if (!isLoaded) {
            return
        }

        collectStatus()
            .then((result) => {
                if (result.success) {
                    setAuthorizationSuccess(true)
                } else {
                    proceedWithError(new Error('Not successful authorization status'))
                }
            })
            .catch((err) => proceedWithError(err))
    }, [
        isLoaded,
        collectStatus
    ])

    return authorizationSuccess
}