import {config} from "../../config/config"

export const logger = {
    debug(message: unknown, ...optionalParams: unknown[]): void {
        if(config.logger.enabled && config.logger.level === 'debug') {
          console.debug(message, optionalParams)
        }
    },
    info(message: unknown, ...optionalParams: unknown[]): void {
        if(config.logger.enabled && ['debug', 'info'].includes(config.logger.level)) {
            console.info(message, optionalParams)
        }
    },
    error(message: unknown, ...optionalParams: unknown[]): void {
        if(config.logger.enabled) {
            console.error(message, optionalParams)
        }
    }
}
