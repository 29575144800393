import {CardState} from "./types"
import {RootState} from "../../store"
import {PaymentProvider} from "../../../types/payment-provider"
import {CreditCardType} from "../../../types/credit-card"
import {config} from "../../../config/config"
import {getMonth, getYear, getYearFull} from "../../../services/card/expiration-parser"

export const getCardState = (state: RootState): CardState => state.card

export const getCardNumber = (state: CardState): string => state.data.number

export const getExpirationMonth = (state: CardState): string => getMonth(state.data.expirationDate)

export const getExpirationYear = (state: CardState): string => getYear(state.data.expirationDate)

export const getExpirationYearFull = (state: CardState): string => getYearFull(state.data.expirationDate)

export const getVerificationNumber = (state: CardState): string => state.data.verificationNumber

export const getPaymentProvider = (state: CardState): Nullable<PaymentProvider> => state.paymentProvider

export const getSupportedCardTypes = (state: CardState): CreditCardType[] => {
    return state.paymentProvider
        ? config.payment.providers[state.paymentProvider].supportedCards
        : []
}

export const getCardType = (state: CardState): Nullable<CreditCardType> => state.type

export const getChaseToken = (state: CardState): string => state.chaseToken
