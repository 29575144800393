import {filter, from, identity, interval, Observable, switchMap, tap, timeout} from "rxjs"
import {PollingObservableConfig} from "../types/types"
import {logger} from "../../log/logger"

export const createPollingObservable = <T>(
    {
        pollFn,
        testFn,
        settings: { interval: withInterval, waitFor}
    }: PollingObservableConfig<T>,
): Observable<T> => {
    return interval(withInterval)
        .pipe(
            switchMap<number, Observable<T>>(() => from(pollFn())),
            tap(data => logger.debug('Polling iteration complete', { data })),
            filter(testFn),
            waitFor ? timeout({ each: waitFor }) : identity
        )
}
