import {BaseStyle} from "../base-style"
import unknown from "./icons/unknown-light.svg"
import cardFailure from "./icons/card-failure-light.svg"
import cardSuccess from "./icons/card-success-light.svg"

export const LightStyle: BaseStyle = {
    banner: {
        backgroundPrimary: '#28448f',
        backgroundSecondary: '#f6f7f7',
        text: '#fff',
    },
    errorPage: {
        catchPhrase: '#0d3ecc',
        text: '#494949',
    },
    svg: {
        checkmark: '#25AC4D',
        cardFailure,
        cardSuccess,
        unknown,
    }
}