import {useAppDispatch} from "../../../../../store/hooks/use-app-dispatch"
import {useEffect} from "react"
import {useParams} from "react-router-dom"
import {actionCreators, thunks} from "../../../../../store/slices/session"
import {useSessionStateSelector} from "../../../../../store/slices/session/hooks"
import {sessionIsLoaded, sessionIsLoading, sessionLoadingFailed} from "../../../../../store/slices/session/selectors"

type UseSessionResult = {isLoading: boolean, isFailed: boolean}

export const useSessionFetch = (): UseSessionResult => {
    const [isLoading, isFailed, isLoaded] = useSessionStateSelector(state => ([
        sessionIsLoading(state),
        sessionLoadingFailed(state),
        sessionIsLoaded(state)
    ]))

    const dispatch = useAppDispatch()

    const {id, pwd} = useParams()

    useEffect(() => {
        if(!isLoaded) {
            dispatch(actionCreators.setSessionParams({id, pwd}))

            dispatch(thunks.fetchSession())
        }
    },[isLoaded])

    return {isLoading, isFailed}
}
