import styled from "styled-components"
import {VFC} from "react"
import {useTranslation} from "react-i18next"
import {useSessionStateSelector} from "../../../../store/slices/session/hooks"
import {getFlow} from "../../../../store/slices/session/selectors"
import {Flow} from "../../../../types/flow"

export const TitleText= styled.div`
  padding: 0 1rem 1.5rem;
  text-align: left;
  font-size: 24px;
  font-weight: 500;
`

export const FormTitle: VFC = () => {
    const { t } = useTranslation()

    const flow = useSessionStateSelector(state => getFlow(state))

    return (
        <TitleText>
            { flow === Flow.REPLACE_CARD
                ? t('form.titleReplace')
                : t('form.titleCreate')
            }
        </TitleText>
    )
}
