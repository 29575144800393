import {CreditCardType} from "../../types/credit-card"

const map: Record<CreditCardType, string> = {
    [CreditCardType.VISA]: 'Visa',
    [CreditCardType.MASTERCARD]: 'Mastercard',
    [CreditCardType.MAESTRO]: 'Maestro',
    [CreditCardType.DISCOVER]: 'Discover',
    [CreditCardType.JCB]: 'JCB',
    [CreditCardType.AMEX]: 'American Express',
}

export const cardNameByType = (type: CreditCardType): string => map[type]
