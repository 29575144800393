import {PaymentProvider} from "../../../../../types/payment-provider"
import {ErrorMessages} from "../../../../../types/config.types"
import {messages} from "../../../../../config/payment/error-messages"

export const getErrorMessage = (messages: ErrorMessages) => {
    return (provider: PaymentProvider, code: string): Optional<string> => {
        return messages[provider].find(({codes}) => codes.includes(code))?.message
    }
}

export const getErrorMessageWithConfig = (provider: PaymentProvider, code: string): Optional<string> => {
    return getErrorMessage(messages)(provider, code)
}
