import {linkTo, pathTo} from "../../services/utility/routes/helpers"
import {RouteParams} from "../../services/utility/routes/types"

export const routes = {
    session: {
        prefix: '/session/:id/:pwd',
        getPath: (): string => pathTo(routes.session),
        getLink: (params: RouteParams): string => linkTo(routes.session, params),
        customer: {
            prefix: 'customer',
            getPath: (): string => pathTo(routes.session, routes.session.customer),
            getLink: (params: RouteParams): string => linkTo(routes.session.customer, params),
        },
        processing: {
            prefix: 'processing/:collectorId',
            getPath: (): string => pathTo(routes.session, routes.session.processing),
            getLink: (params: RouteParams): string => linkTo(routes.session.processing, params),
            authNet: {
                prefix: 'auth-net',
                getPath: (): string => pathTo(
                    routes.session,
                    routes.session.processing,
                    routes.session.processing.authNet
                ),
                getLink: (params: RouteParams): string => linkTo(routes.session.processing.authNet, params),
            },
            chase: {
                prefix: 'chase',
                getPath: (): string => pathTo(
                    routes.session,
                    routes.session.processing,
                    routes.session.processing.chase
                ),
                getLink: (params: RouteParams): string => linkTo(routes.session.processing.chase, params),
            },
        },
        delivering: {
            prefix: 'delivering',
            getPath: (): string => pathTo(routes.session, routes.session.delivering),
            getLink: (params: RouteParams): string => linkTo(routes.session.delivering, params),
        },
        confirmation: {
            prefix: 'confirmation',
            getPath: (): string => pathTo(routes.session, routes.session.confirmation),
            getLink: (params: RouteParams): string => linkTo(routes.session.confirmation, params),
        },
        failed: {
            prefix: 'failed',
            getPath: (): string => pathTo(routes.session, routes.session.failed),
            getLink: (params: RouteParams): string => linkTo(routes.session.failed, params),
        },
    }
}
