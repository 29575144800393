import {ValidationFn} from "../../types"
import {toYupTest} from "../../helpers/to-yup-test"
import i18n from "../../../../i18n"
import {TestConfig} from "yup"
import {postalCode} from '@malwarebytes/geography-library'

const {isPostalCodeValid} = postalCode

export const postalCodeIsValid = (country: string, stateProvince: string): ValidationFn<string | number> => (value) => {
    return isPostalCodeValid({
        required: i18n.t('validation.address.postalCode.required'),
        notSupported: i18n.t('validation.address.postalCode.notSupported'),
        invalid: i18n.t('validation.address.postalCode.valid'),
        invalidUS: i18n.t('validation.address.postalCode.postalCodeNotMatchState'),
    })(country, stateProvince)(value)
}

export const asYupTest = (country: string, stateProvince: string): TestConfig<string | undefined> => toYupTest({
    name: 'postalCodeIsValid',
    validationFn: postalCodeIsValid(country, stateProvince)
})
