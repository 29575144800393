import {useCallback} from "react"
import {fetchPaymentMethodId} from "../../../../../api/lira/lira"
import {usePollingObservable} from "../../../../../services/realtime/hooks/use-polling-observable"
import {config} from "../../../../../config/config"
import {useSessionStateSelector} from "../../../../../store/slices/session/hooks"
import {getSessionParams} from "../../../../../store/slices/session/selectors"
import {PaymentMethodIdAware} from "../../../../../api/lira/types"
import {Observable} from "rxjs"

export const useProcessingPoller = (): Observable<PaymentMethodIdAware> => {
    const params = useSessionStateSelector(state => (getSessionParams(state)))

    const pollFn = useCallback(
        () => fetchPaymentMethodId(),
        []
    )
    const testFn = useCallback(
        ({paymentMethodId}: PaymentMethodIdAware) => Boolean(paymentMethodId),
        []
    )

    return usePollingObservable(
        {
            pollFn,
            testFn,
            settings: config.timeout.processing.authNet.polling,
        },
        Boolean(params.id && params.pwd)
    )
}
