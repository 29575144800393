export const getMonth = (expiration: string): string => {
    return expiration.substring(0, 2)
}

export const getYear = (expiration: string): string => {
    return expiration.substring(expiration.length - 2, expiration.length)
}

export const getYearFull = (expiration: string): string => {
    return `20${getYear(expiration)}`
}

export const parse = (expiration: string): {month: string, year: string} => {
    return {
        month: getMonth(expiration),
        year: getYear(expiration)
    }
}
