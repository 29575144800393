import {Loader} from "../../../../components/general/loader/loader"
import {useTranslation} from "react-i18next"
import {withAuthorizationStatusReceiving} from "./with-authorization-status-receiving"
import {withPaymentMethodCollecting} from "./with-payment-method-collecting"
import {ComponentType} from "react"

export const ChaseProcessing: ComponentType = withAuthorizationStatusReceiving(
    withPaymentMethodCollecting(() => {
            const { t } = useTranslation()

            return (
                <Loader
                    transparent={false}
                    header={t('loader.header.processing')}
                    text={t('loader.text.processing')}
                />
            )
        })
)
