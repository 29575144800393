import {Subject} from 'rxjs'
import {initMarketingSubscribers} from './systems'

type ActionEventData = Record<string, unknown>

export const pageViews = new Subject<{ url: string}>()
export const events = new Subject<{ action: string, data: ActionEventData }>()

export const emitPageView  = (page: string): void => {
    pageViews.next({
        url: page,
    })
}

export const emitActionEvent = (action: string, data: ActionEventData): void => {
    events.next({
        action,
        data
    })
}

initMarketingSubscribers()

/**
 * Example of usage below. Import the subject in your marketing system to emit the value
 * Make sure to add your subscriber function into marketingSystems array of systems/index.ts
 * That will allow it to start listening to our events here.
 */

// pageViews
//     .subscribe((event) => {
//         console.log('report to ga', event.url)
//     })
//
// pageViews
//     .subscribe((event) => {
//         console.log('report to something else', event.url)
//     })
//
// events
//     .subscribe((event) => {
//         console.log('Event', event.handle, event.data.position)
//     })
