export class ChaseFormTracker {

    listeners = new Map()

    cardNumber = false
    cardExpiration = false
    securityCode = false
    cardHolder = false

    cardBrand: Nullable<string> = null

    setCardNumber(isValid: boolean) {
        this.cardNumber = isValid

        this.emitChange('changed', {isValid: this.isValid()})
    }

    setCardExpiration(isValid: boolean) {
        this.cardExpiration = isValid

        this.emitChange('changed', {isValid: this.isValid()})
    }

    setSecurityCode(isValid: boolean) {
        this.securityCode = isValid

        this.emitChange('changed', {isValid: this.isValid()})
    }

    setCardHolder(isValid: boolean) {
        this.cardHolder = isValid

        this.emitChange('changed', {isValid: this.isValid()})
    }

    setCardBrand(brand: string) {
        this.cardBrand = brand

        this.emitChange('brand-detected', {brand})
    }

    emitChange(event: string, payload: any) {
        this.listeners.forEach(
            (listener) => listener({event, payload})
        )
    }

    /**
     * Public methods below
     */

    reset() {
        this.setCardNumber(false)
        this.setCardExpiration(false)
        this.setSecurityCode(false)
        this.setCardHolder(false)
    }

    isValid() {
        return this.cardNumber && this.cardExpiration && this.securityCode && this.cardHolder
    }

    subscribe(listener: (input: { event: string, payload: any }) => void) {
        const key = Symbol(Date.now())

        this.listeners.set(key, listener)

        return () => this.listeners.delete(key)
    }
}