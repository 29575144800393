import {ServerProvidedClient, ServerProvidedConnection} from "../../../types/types"
import {pusher} from "../../../pusher"
import {Channel} from "pusher-js"

export const connectionWrapper = <T>(connection: Channel): ServerProvidedConnection<T> => {
    return {
        listen(
            eventName: string,
            onEvent: (data: T) => void,
            onError
        ): void {
            connection.bind(eventName, onEvent)

            pusher.connection.bind('error', (err: Error) => onError(err))
        },
        unsubscribe(): void
        {
            connection.unbind_all()
            connection.cancelSubscription()
        }
    }
}

export const pusherAdapter = <T>(): ServerProvidedClient<T> => {
    return {
        subscribe(channelName: string): ServerProvidedConnection<T> {
            const connection = pusher.subscribe(channelName)

            return connectionWrapper<T>(connection)
        }
    }
}
