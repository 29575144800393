import {VFC} from "react"
import {verificationNumberLength} from "../../../../../../../../services/card/verification-number-length"
import styled from "styled-components"
import {useTranslation} from "react-i18next"
import {CreditCardType} from "../../../../../../../../types/credit-card"

const PopoverText = styled.div`
  margin: auto;
  padding: 0;
  max-width: 200px;
  text-align: center;
  font-size: 14px;
  line-height: 1.43;
`

export const OverlayText: VFC<{ cardType: Nullable<CreditCardType> }> = ({cardType}) => {
    const {t} = useTranslation()

    return (
        <PopoverText as="div">
            {t('validation.card.verificationNumber.tooltip',
                {
                    size: verificationNumberLength(cardType)
                }
            )}
        </PopoverText>
    )
}
