import {ApiConfig} from "../../types/config.types"
import axios, {AxiosRequestConfig, AxiosResponse} from "axios"
import {store} from "../../store/store"
import {getAuthToken, getSessionState} from "../../store/slices/session/selectors"
import {attachLoggerInterceptors} from "./attach-logger-interceptors"

export type HttpClient = {
    fetch: <Req, Res>(config: AxiosRequestConfig<Req>) => Promise<AxiosResponse<Res>>,
    fetchData: <Req, Res>(config: AxiosRequestConfig<Req>) => Promise<Res>,
}

export const createClient = ({url}: ApiConfig): HttpClient => {
    const client = axios.create({
        baseURL: url,
        headers: {
            ['Content-Type']: 'application/json',
            ['Accept']: 'application/json',
        },
    })

    // attachLoggerInterceptors(client)

    const fetch = <Req, Res>(config: AxiosRequestConfig<Req>): Promise<AxiosResponse<Res>> => {
        const token = getAuthToken(getSessionState(store.getState()))
        return client.request<Res>({
            ...config,
            headers: {
                ...(config.headers || {}),
                ['Authorization']: `Basic ${token}`
            }
        })
    }

    return {
        fetch,
        fetchData: async <Req, Res>(config: AxiosRequestConfig<Req>): Promise<Res> => {
            const response = await fetch<Req, Res>(config)

            return response.data
        }
    }
}
