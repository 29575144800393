import cardValidator from "card-validator"
import {CreditCardType} from "../../types/credit-card"

export const cardTypeByNumber = (number: string | undefined): Nullable<CreditCardType> => {
    const card = cardValidator.number(number)

    if(!card.card?.type) {
        return null
    }

    const map: Record<string, CreditCardType> = {
        'visa': CreditCardType.VISA,
        'mastercard': CreditCardType.MASTERCARD,
        'american-express': CreditCardType.AMEX,
        'maestro': CreditCardType.MAESTRO,
        'discover': CreditCardType.DISCOVER,
        'jcb': CreditCardType.JCB,
    }

    return map[card.card.type] || null
}
