import {useEffect, useState} from "react"
import {EMPTY, mergeWith, Observable} from "rxjs"
import {config} from "../../../../../../config/config"
import {useClicksTracker} from "./use-clicks-tracker"
import {useKeyPressTracker} from "./use-key-press-tracker"
import {UseActivityProps} from "../../types/types"

export const useActivityTracker = (props: UseActivityProps): Observable<Event> => {
    const [tracker$, setTracker] = useState<Observable<Event>>(EMPTY)

    const clicks$ = useClicksTracker(props)
    const keyPresses$ = useKeyPressTracker(props)

    useEffect(() => {
        setTracker(
            clicks$
                .pipe(mergeWith(keyPresses$))
        )
    }, [clicks$, keyPresses$])

    return tracker$
}

export const useActivityTrackerWithConfig = (): Observable<Event> => useActivityTracker(config.iframe.activityTracking)
