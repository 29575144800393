import {FC} from "react"
import {useTranslation} from "react-i18next"
import {Banner} from "../banner"
import {sendToParent} from "../../../../../services/iframe/send-to-parent"
import {IframeEvent} from "../../../../../config/iframe/iframe-event"
import {FullWidthButton} from "./full-width-button"
type CloseBlockProps = {
    event?: IframeEvent,
}
export const CloseBlock: FC<CloseBlockProps> = (props) => {
    const {t} = useTranslation()

    return (
        <Banner type="secondary">
            <FullWidthButton
                onClick={() => sendToParent(props.event || IframeEvent.CLOSE_BLOCK)}
            >
                {t('buttons.close')}
            </FullWidthButton>
        </Banner>
    )
}
