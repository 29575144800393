import {useCardStateSelector} from '../../../../../store/slices/card/hooks'
import {getCardType} from '../../../../../store/slices/card/selectors'
import {verificationNumberMask} from '../../../../../services/card/mask/verification-number-mask'
import {MaskData} from "../types/types"

export const useVerificationNumberMask = (): MaskData => {
    const cardType = useCardStateSelector(state => getCardType(state))

    return {
        maskPlaceholder: '',
        mask: verificationNumberMask(cardType),
    }
}
