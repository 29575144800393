import {Dispatch, SetStateAction, useEffect, useState} from "react"
import {useQueryParameters} from "../use-query-parameters"
import {ThemeType} from "../../types/theme"
import {config} from "../../config/config"

export const useTheme = (): [ThemeType, Dispatch<SetStateAction<ThemeType>>] => {
    /**
     * Using the useQueryParameters hook will allow one render of the document to happen because of react lifecycle
     * That initial re-render will cause us to go to the App's default theme, which could cause a flicker when the user's
     * action theme is loaded. window.location.search will give us access immediately. BONP-1885.
     * Using useLayoutEffect isnt enough here
      */

    const queryParams = useQueryParameters()
    const defaultTheme =
        (window.location.search.search('dark') > -1 ? ThemeType.DARK : ThemeType.LIGHT)
        || config.app.defaultTheme
    const [theme, setTheme] = useState<ThemeType>(defaultTheme)

    useEffect(() => {
        const theme = <Optional<ThemeType>>queryParams.theme
        if(theme) {
            setTheme(theme)
        }
    }, [queryParams])

    useEffect(() => {
        document.body.className = theme
    }, [theme])

    return [theme, setTheme]
}
