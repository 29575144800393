import styled from "styled-components"
import {FormControlFeedback} from "@malwarebytes/react-components/build/form"
import {VFC} from "react"

type ErrorBlockProps = {
    error: string
}

const FeedbackControl = styled(FormControlFeedback)`
    display: block;
`

export const ErrorBlock: VFC<ErrorBlockProps> = ({error}) => {
    return <FeedbackControl type='invalid'>
        {error}
    </FeedbackControl>
}
