import { PayloadAction } from '@reduxjs/toolkit'

type DefaultExtraReducer<State, Payload> = (state: State, action: PayloadAction<Payload>) => void

export type DefaultExtraReducersTree<State, Payload> = {
    [key: string]: DefaultExtraReducer<State, Payload>
}

export enum LoadingState {
    PENDING = 'pending',
    LOADING = 'loading',
    LOADED = 'loaded',
    FAILED = 'failed',
}

export type SliceSelector<TState, TSelected> = (state: TState) => TSelected
