import {VFC} from 'react'
import {Outlet} from "react-router-dom"
import {useSessionFetch} from "./hooks/use-session-fetch"
import {Loader} from "../../../components/general/loader/loader"
import {useTranslation} from "react-i18next"
import {SessionNotFound} from "../../../components/errors/session-not-found"

export const SessionAware: VFC = () => {
    const {t} = useTranslation()

    const {isLoading, isFailed} = useSessionFetch()

    if (isFailed) {
        return <SessionNotFound />
    }

    return (
        <div>
            {isLoading && (
                <Loader
                    header={t('loader.header.loading')}
                    transparent={false}
                />
            )}
            <Outlet />
        </div>
    )
}
