import {ServerProvidedClient, ServerProvidedObservableConfig} from "../../types/types"
import {identity, map, Observable, timeout} from "rxjs"
import {makeTimoutConfig} from "../../hooks/server-provided/helpers/make-timeout-config"
import {logger} from "../../../log/logger"

export const createServerProvided = <ST, RT>(
    client: ServerProvidedClient<ST>,
    {
        channelName,
        eventName,
        fallbackTo,
        transformer,
        settings
    }: ServerProvidedObservableConfig<ST, RT>
): Observable<RT> => {
    const timeoutConfig = makeTimoutConfig({
        settings,
        fallbackTo
    })

    return new Observable<ST>(subscriber => {
        const connection = client.subscribe(channelName)

        connection.listen(
            eventName,
            (data: ST) => {
                logger.debug('Event from server received', { channelName, eventName }, data)

                subscriber.next(data)
            },
            error => {
                logger.error('Error receiving event from server', error)

                subscriber.error(error)
            }
        )

        return () => connection.unsubscribe()
    }).pipe(
        map(d => transformer(d)),
        timeoutConfig ? timeout(timeoutConfig) : identity
    )
}
