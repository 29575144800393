import {config} from "../../config/config"
import {
    GenerateSecurityTokenResult,
    GetCheckoutStatusResult,
    InitiateCheckoutResult,
    PreparePaymentInput,
    PreparePaymentResult
} from "./types"
import {createClient} from "../helpers/create-client"

const {fetchData} = createClient(config.api.jupiter)

export const generateSecurityToken = (): Promise<GenerateSecurityTokenResult> => {
    return fetchData<void, GenerateSecurityTokenResult>({
        method: 'get',
        url: 'api/security/slt/generate',
    })
}

export const preparePayment = (data: PreparePaymentInput): Promise<PreparePaymentResult> => {
    return fetchData<PreparePaymentInput, PreparePaymentResult>({
        method: 'post',
        url: 'api/new/checkout/cart',
        data,
    })
}

export const initiateCheckout = (): Promise<InitiateCheckoutResult> => {
    return fetchData<any, InitiateCheckoutResult>({
        method: 'post',
        url: 'api/new/checkout/initiate',
        data: {}
    })
}

export const getCheckoutStatus = (token: string): Promise<GetCheckoutStatusResult> => {
    return fetchData<any, GetCheckoutStatusResult>({
        method: 'get',
        url: 'api/new/checkout/initiate',
        data: {},
        headers: {
            ['Authorization']: `Basic ${token}`
        }
    })
}