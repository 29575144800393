import {useInitiateCheckout} from "./hooks/use-initiate-checkout"
import {useFormAction} from "./hooks/use-form-action"
import './styles.scss'
import {Spinner} from "@malwarebytes/react-components"

export const ChaseConnector = () => {
    const isInitiated = useInitiateCheckout()

    const formAction = useFormAction()

    if (!isInitiated || !formAction) {
        return (
            <div className='chase-loader'>
                <Spinner />
                <h3>
                    Preparing credit card form
                </h3>
            </div>
        )
    }

    return (
        <div className='px-3'>
            <form action={formAction} className='paymentWidgets' data-brands='VISA MASTER MAESTRO'/>
        </div>
    )
}